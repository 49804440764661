import { ProjectData, carbonProjectAttributes, getBeZeroRating } from '@commodity-desk/common';

// construct project details UI display object from response data
const projectDisplayDetails = (project: any, appConfigState: any) => {

    // eslint-disable-next-line no-lone-blocks
    {/* Client holdings is assignedAmount minus issuerAmount */}
    const clientHoldingsString = (+project.balances.assignedAmount - +project.balances.issuerAmount).toString();

    const displayValues: ProjectData = {
        projectId: project.attributes[carbonProjectAttributes.projectId.key],
        projectName: project.attributes[carbonProjectAttributes.projectName.key],
        beZeroRating: getBeZeroRating(project.attributes[carbonProjectAttributes.projectId.key]),
        country: project.attributes[carbonProjectAttributes.country.key],
        projectState: project.attributes[carbonProjectAttributes.projectState.key],
        projectType: project.attributes[carbonProjectAttributes.projectType.key],
        uri: project.attributes[carbonProjectAttributes.projectUri.key],
        inventoryAmount: project.balances.issuerAmount,
        escrowAmount: project.balances.escrowAmount,
        clientAmountAssigned: clientHoldingsString,
        clientAmountUnassigned: project.balances.unassignedAmount
    };

    return displayValues;
};

export { projectDisplayDetails };
