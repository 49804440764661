import dayjs from 'dayjs';

/**
 * Definition of the three delta ladder levels.
 * Used to drive column selection logic.
 */
enum DeltaLevel {
    L1 = 'L1', // Root (list of products)
    L2 = 'L2', // Product (list by attribute value)
    L3 = 'L3', // Attribute (list of projects)
}

/**
 * The types of rows we can have in tables on this page
 */
enum RowType {
    PHYSICAL = 'PHYSICAL',
    FORWARD = 'FORWARD',
    NET_DELTA = 'NET_DELTA'
}

/**
 * The units of date ranges we can have in rows in this table
 */
enum RangeUnits {
    MONTH = 'MONTH',
    WEEK = 'WEEK',
    DAY = 'DAY'
}

/** The types of columns we can have in tables on this page */
enum ColumnType {
    STANDARD = 'STANDARD',
    UNCLASSIFIED = 'UNCLASSIFIED',
    TOTAL = 'TOTAL'
}

/**
 * Used to define URL fragments that are replaced in URL templates when constructing URLs to external pages
 */
enum UrlFragment {
    PRODUCT_ID = '<PRODUCT_ID>',
    PRODUCT_BASE = '<PRODUCT_BASE>',
    L3_BASE_ATTRIBUTE_VALUE = '<L3_BASE_ATTRIBUTE_VALUE>'
}

/**
 * The row definition used to define the required rows for this delta ladder, used as input to DeltaLadder
 */
class RowDefinition {
    label: string;
    rowType: RowType;
    monthNumber?: number;

    constructor(
        label: string,
        rowType: RowType,
        monthNumber?: number
    ) {
        this.label = label;
        this.rowType = rowType;
        this.monthNumber = monthNumber;
    }
}

interface RiskAttributeBreakdownOption {
    displayName: string,
    attribute: string,
    isDefault?: boolean,
    conditions?: DeltaLadderOptionCondition[],
    urlParamName: string
}

interface BaseAttributeOption {
    attributeKey: string,
    conditions?: DeltaLadderOptionCondition[],
    urlParamName: string
}

interface DeltaLadderOptionCondition {
    l1AttributeValue?: string[]
}

interface DisplayCode {
    id: string,
    displayCode: string,
}

interface DetailsViewUrlParameters {
    baseUrl: string,
    valueDateStartUrlParam: string,
    valueDateEndUrlParam: string,
    otherUrlParams: Array<string>[]
}

interface ProductInfo {
    id: string,
    code: string,
    base: string,
    minDecimalPlaces: number,
    maxDecimalPlaces: number
}

const TOTAL_COLUMN_LABEL = 'Total';
const DATE_DISPLAY_FORMAT = 'YYYY-MM-DD';

/**
 * A representation of a particular row in the table. Does not contain any data (that is contained within Column)
 * but does contain all metadata needed to describe each row.
 *
 * @property index      The index of this Row in the overall list of all possible Rows
 * @property label      The display label
 * @property rowType    The type of the row
 * @property dateRange  The date range that applies to this row, including month / week / day number as well as start and end date
 */
class Row {
    index: number;
    label: string;
    rowType: RowType;
    dateRange?: DateRange;
    isExpanded?: boolean;

    constructor(
        index: number,
        label: string,
        rowType: RowType,
        dateRange?: DateRange
    ) {
        this.index = index;
        this.label = label;
        this.rowType = rowType;
        this.dateRange = dateRange;
        this.isExpanded = false;
    }

    /* Whether this row can be expanded into finer-grained levels by clicking on it in the table */
    canExpand = () => {
        if (![RowType.PHYSICAL, RowType.NET_DELTA].includes(this.rowType) && this.dateRange?.rangeUnits) {
            return [RangeUnits.MONTH, RangeUnits.WEEK].includes(this.dateRange?.rangeUnits);
        }
        return false;
    }

    /* The tooltip shown when hovering over the row label on the left hand side of the table */
    tooltip = () => {
        if (this.dateRange === undefined || this.rowType !== RowType.FORWARD || this.dateRange.rangeUnits === RangeUnits.DAY) {
            return '';
        }
        return `${this.label} - from ${this.dateRange!.startDate.format(DATE_DISPLAY_FORMAT)} to ${this.dateRange!.endDate.add(-1, 'day').format(DATE_DISPLAY_FORMAT)}`;
    }

    /* Returns a date range string in Core10 format, used to query data matching this range */
    getQueryRangeString = () => {
        return `${this.dateRange?.startDate?.toISOString() ?? ''}..${this.dateRange?.endDate?.toISOString() ?? ''}`;
    }
}

/**
 * Represents a date range, including start date and end date in dayjs form, as well as range start and end numbers and the units of measurement for the range.
 * 
 * @property rangeStart  The range start number (e.g. month 1 or month 3)
 * @property rangeEnd    The range end number (e.g. week 2 or week 4)
 * @property rangeUnits  The units of measurement for this range, e.g. months, weeks or days
 * @property startDate   The actual start date of this range in dayjs format
 * @property endDate     The actual end date of this range in dayjs format
 */
class DateRange {
    rangeStart: number;
    rangeEnd: number;
    rangeUnits: RangeUnits;
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;

    constructor(
        rangeStart: number,
        rangeEnd: number,
        rangeUnits: RangeUnits,
        startDate: dayjs.Dayjs,
        endDate: dayjs.Dayjs
    ) {
        this.rangeStart = rangeStart;
        this.rangeEnd = rangeEnd;
        this.rangeUnits = rangeUnits;
        this.startDate = startDate;
        this.endDate = endDate;
    }
}

/**
 * A column represents the physical balance and a set of monthly projections,
 * aggregated on an arbitrary "axis", e.g.: Product, Project Type, Vintage etc.
 */
class Column {
    readonly key: string;
    readonly label: string;
    readonly columnType: ColumnType;
    private readonly ladder: Array<number>;

    private constructor(key: string, columnType: ColumnType, ladder: Array<number>, label?: string) {
        this.key = key;
        this.columnType = columnType;
        this.ladder = ladder;
        this.label = label ?? key;
    }

    /**
     * Construct a Column from a set of input balances
     * @param key              The key for this column
     * @param columnType       The type of column
     * @param rows             The set of rows that apply to this column
     * @param forwardBalances  An array of forward balances
     * @param physicalBalance  The input physical balance
     * @param label            The display label for the column
     * @returns                A Column
     */
    static fromBalances = (key: string, columnType: ColumnType, rows: Row[], forwardBalances: number[], physicalBalance?: number, label?: string) => {
        let ladder = new Array<number>(rows.length);
        let forwardIdx = 0;
        for (let row of rows) {
            switch (row.rowType) {
                case RowType.PHYSICAL:
                    ladder[row.index] = physicalBalance!;
                    break;
                case RowType.FORWARD:
                    ladder[row.index] = -forwardBalances[forwardIdx];
                    forwardIdx += 1;
                    break;
                case RowType.NET_DELTA:
                    // This logic only currently works when the Net Delta column is at the end.
                    // If this ever changes, the logic will need to be updated here.
                    ladder[row.index] = ladder.reduce((sum, item) => sum + +item, 0);
                    if (row.index !== rows.length - 1) {
                        throw new Error(`Net delta calculation is incorrect, logic must be updated`);
                    }
                    break;
                default:
                    throw new Error(`Unexpected RowType ${row.rowType}`);
            }
        }
        return new Column(key, columnType, ladder, label);
    };

    /**
     * Construct a Column by summing an input set of columns. Used for Total columns.
     * @param key      The key for the new summary column
     * @param columns  The input set of columns used to create the summary column
     * @param rows     The row information that corresponds to the data arrays within the provided columns
     * @returns        A summary Column
     */
    static sumFromColumns = (key: string, columns: Array<Column>, rows: Row[]) => {
        let ladder = new Array<number>(rows.length);
        for (let row of rows) {
            ladder[row.index] = columns
                .map(col => col.get(row.index))
                .reduce((sum, item) => sum + +item, 0);
        }
        return new Column(key, ColumnType.TOTAL, ladder);
    };

    get = (index: number) => this.ladder[index];
}

/**
 * A representation of a particular delta ladder level (see {@link DeltaLevel}).
 *
 * Each {@link DeltaLevelSummary} holds data necessary to fully populate the Delta Ladder table and can be
 * "plugged-in" into the view via `setPrimarySummary` or `setSecondarySummary`.
 *
 * @property rows         The array of {@link Row} objects, containing key info about each row of the table, such as date range and label
 * @property columns      The array of {@link Column}s that contain the data for this table
 * @property level        The level number of this summary, e.g. L1, L2, or L3
 * @property key          The key identifier for this summary. For L1 this is the string "ROOT". For L2 it is a product code.
 *                        For L3 it is the value of the attribute that we selected in the L2 table. This is used as a filter on data queries.
 * @property label        The label to be used to display this summary. Optional (key is used as a fallback)
 * @property groupBy      The attribute used in the group-by query for this table of data. For L1 this is product code. For L2 it is whatever attribute
 *                        is selected in the L2 drop down box. For L3 it is usually the Project attribute, or otherwise for LGC it is the Certificate attribute
 * @property total        The totals column, created by summing the other columns
 * @property expandedRows Contains the set of rows that have been expanded by clicking on them. The key of this structure is the original parent row
 *                        that was clicked on to expand into child rows. The {@link ExpandedRows} object contains all details for the expanded rows.
 * @property parent       The parent summary object, e.g. for the L3 summary, the parent would be L2. Only defined for L3 table, and only used for CSV export filename.
 * @property orderAlphabetically  Whether to order columns alphabetically (the default), or whether to keep the original constructed order.
 */
class DeltaLevelSummary {
    readonly rows: Array<Row>;
    readonly columns: Array<Column>;
    readonly level: DeltaLevel;
    readonly key: string;
    readonly label: string;
    readonly groupBy?: string;
    readonly total: Column;
    readonly expandedRows: Map<string, ExpandedRows>;
    readonly parent?: DeltaLevelSummary;
    readonly orderAlphabetically?: boolean;

    constructor({
        rows,
        columns,
        level,
        key,
        label,
        groupBy,
        expandedRows,
        parent,
        orderAlphabetically = true
    }: {
        rows: Array<Row>;
        columns: Array<Column>;
        level: DeltaLevel;
        key: string;
        label?: string;
        groupBy?: string;
        expandedRows?: Map<string, ExpandedRows>;
        parent?: DeltaLevelSummary;
        orderAlphabetically?: boolean;
    }) {
        this.rows = rows;
        // sort columns alphabetically, for consistent presentation between loads
        if (orderAlphabetically) {
            this.columns = columns.sort((a, b) => {
                // but ensure any unclassified columns are listed last
                if (a.columnType === ColumnType.UNCLASSIFIED) return 1;
                if (b.columnType === ColumnType.UNCLASSIFIED) return -1;
                return (a.label ?? "ZZZ").toString().localeCompare(b.label ?? "ZZZ");
            });
        } else {
            this.columns = columns;
        }
        this.level = level;
        this.key = key;
        this.label = label ?? key;
        this.groupBy = groupBy;
        this.expandedRows = expandedRows ?? new Map<string, ExpandedRows>();
        this.parent = parent;
        this.orderAlphabetically = orderAlphabetically;
        this.total = Column.sumFromColumns(TOTAL_COLUMN_LABEL, columns, rows);
    }

    clone = () => {
        return new DeltaLevelSummary({rows: this.rows, columns: this.columns, level: this.level, key: this.key, label: this.label, groupBy: this.groupBy, expandedRows: this.expandedRows, parent: this.parent, orderAlphabetically: this.orderAlphabetically});
    }

    is = (level: DeltaLevel) => this.level === level;
}

/**
 * Represents a set of rows that are created by expanding one of the base rows of a Delta Ladder table. Expanded rows appear underneath
 * the corresponding base row in a different colour and contain a more fine-grained date range breakdown, for example months will break down
 * into weeks, and weeks into days.
 * 
 * @property baseRowKey            The base row that was clicked on in the parent element, in order to expand out into these expanded rows
 * @property expandedRowKeys       The current set of rows for the expanded rows we are showing in the table
 * @property columns               The columns of data we are showing in the expanded table
 * @property totalColumn           The totals column we show in the expanded table
 * @property expansionDepth        A number representing how many levels deep this set of expanded rows is (starting from 1 for the first set
 *                                 of expanded rows, then increasing by 1 for each additional nested set of expanded rows)
 * @property sublevelExpandedRows  An optional set of sub-level expanded rows, obtained by expanding one of the rows here (if it is able to be expanded)
 */
class ExpandedRows {
    readonly baseRow: Row;
    readonly expandedRows: Row[];
    readonly columns: Array<Column>;
    readonly totalColumn: Column;
    readonly expansionDepth: number;
    readonly sublevelExpandedRows: Map<string, ExpandedRows>;

    constructor(baseRowKey: Row, expandedRows: Row[], columns: Array<Column>, expansionDepth: number, sublevelExpandedRows?: Map<string, ExpandedRows>) {
        this.baseRow = baseRowKey;
        this.expandedRows = expandedRows;
        this.columns = columns;
        this.columns.sort((a, b) => {
            // but ensure any unclassified columns are listed last
            if (a.columnType === ColumnType.UNCLASSIFIED) return 1;
            if (b.columnType === ColumnType.UNCLASSIFIED) return -1;
            return (a.label ?? "ZZZ").toString().localeCompare(b.label ?? "ZZZ");
        });
        this.totalColumn = Column.sumFromColumns(TOTAL_COLUMN_LABEL, columns, expandedRows);
        this.expansionDepth = expansionDepth;
        this.sublevelExpandedRows = sublevelExpandedRows ?? new Map<string, ExpandedRows>();
    }
}

// Display value for trade attributes that are undefined.
const UNCLASSIFIED_LABEL = 'Unclassified';

// A placeholder values to use as column key for unclassified columns
const UNCLASSIFIED_COLUMN_KEY = 'UNCLASSIFIED_COLUMN_KEY';

export {
    type BaseAttributeOption,
    Column,
    ColumnType,
    DateRange,
    type DeltaLadderOptionCondition,   
    type DetailsViewUrlParameters,
    type DisplayCode, 
    ExpandedRows,
    type ProductInfo,
    type RiskAttributeBreakdownOption,
    RangeUnits,
    RowDefinition,
    Row,
    RowType,
    DeltaLevelSummary,
    DeltaLevel,
    UrlFragment,
    DATE_DISPLAY_FORMAT,
    TOTAL_COLUMN_LABEL,
    UNCLASSIFIED_LABEL,
    UNCLASSIFIED_COLUMN_KEY
};