import {
    appThemes, 
    AuthExtension, 
    CustomTheme, 
    NotificationProvider,
    cortenApiKey,
    cortenApiUrl,
    cortenWebsocketUrl,
    StorageItem,
    useAppConfigState
} from '@commodity-desk/common';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { AccessKeyProvider, AuthProvider, useAccessKey, useAuth } from '@trovio-tech/trovio-core-api-jsx';
import { useState } from 'react';
import { StompSessionProvider, useSubscription } from 'react-stomp-hooks';
import './App.css';
import AppContainer from './component/AppContainer';
import {
    ADMIN_GROUP_ID,
    STORAGE_KEY_PREFIX
} from './state/Variables';


function App({ cortenAppConfig }: any) {
    var themeName: keyof typeof appThemes = cortenAppConfig.defaultTheme;
    if (!themeName || !appThemes.hasOwnProperty(themeName)) {
        themeName = 'trovio-dark';
    }

    const appConfigState = useAppConfigState();
    const [theme, setTheme] = useState<CustomTheme>(appThemes[themeName]);
    const [themeAnchorEl, setThemeAnchorEl] = useState();
    StorageItem.initItems(STORAGE_KEY_PREFIX);

    return (
        <ThemeProvider theme={theme.theme}>
            <CssBaseline />
            <AccessKeyProvider
                cortenApiUrl={cortenApiUrl}
                storageKeyPrefix={STORAGE_KEY_PREFIX}
            >
                <AuthProvider
                    appConfig={cortenAppConfig}
                    storage={localStorage}
                    cortenApiUrl={cortenApiUrl}
                    cortenApiKey={cortenApiKey}
                    useDefaultSignInRedirect={false}
                    needsAuthAlways={true}
                    storageKeyPrefix={STORAGE_KEY_PREFIX}
                >
                    <AuthConsumer
                        theme={theme}
                        setTheme={setTheme}
                        themeAnchorEl={themeAnchorEl}
                        setThemeAnchorEl={setThemeAnchorEl}
                    />
                </AuthProvider>
            </AccessKeyProvider>
        </ThemeProvider>
    );
}

// In order to use useAuth(), we need to wrap it in a component that is also wrapped in AuthProvider
const AuthConsumer = ({
    theme,
    setTheme,
    themeAnchorEl,
    setThemeAnchorEl
}: {
    theme: CustomTheme;
    setTheme: any;
    themeAnchorEl: any;
    setThemeAnchorEl: any;
}) => {
    const {userManager, user, signOut} = useAuth();
    const { currentKey } = useAccessKey();

    return (
        <>
            <AuthExtension
                cognitoGroupId={ADMIN_GROUP_ID}
                userManager={userManager}
                user={user}
                signOut={signOut}
            >
                <StompSessionProvider url={cortenWebsocketUrl}>
                    <NotificationProvider currentKey={currentKey} useSubscription={useSubscription}>
                        <AppContainer
                            theme={theme}
                            setTheme={setTheme}
                            themeAnchorEl={themeAnchorEl}
                            setThemeAnchorEl={setThemeAnchorEl}
                        />
                    </NotificationProvider>
                </StompSessionProvider>
            </AuthExtension>
        </>
    );
};

export default App;
