import {
    Box,
    Button,
    LinearProgress,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Typography
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
    AmountFormatWrapper,
    fetchProducts,
    HoldingRetirement,
    inventoryAccount,
    productAttributes,
    ProductDataRaw,
    StyledTabs,
    sectionSpace,
    Transactions,
    transactionAttributes,
    useAppConfigState,
    useLayoutState,
    useProductDataState,
    useCommodityDeskApiContext,
} from '@commodity-desk/common';
import { 
    useAuth,
    useCortenApiState 
} from '@trovio-tech/trovio-core-api-jsx';
import { SyntheticEvent, useEffect, useState } from 'react';
import { HoldingPageType, Holdings } from '../../component/Holdings';
import { BalanceData, ListBalancesRequest } from '@trovio-tech/trovio-core-api-js';
import { ColumnSet } from '../../utility/ColumnSetSelectorUtil';

// Differs from BalanceData in Shared.ts which is for product item balances only
type ProductBalanceData = {
    issuerAmount: string;
    assignedAmount: string;
    unassignedAmount: string;
    escrowAmount: string;
    productId: string;
};

type AccountBalanceInfo = {
    productId: string;
    productCode: string;
    productName: string;
    registryId: string;
    assignedAmount: string;
    unassignedAmount: string;
};

const ClientManagement = () => {
    const [products, setProducts] = useState<ProductDataRaw[] | undefined>(undefined);
    const [addressId, setAddressId] = useState<string>('');
    const [validAddressId, setValidAddressId] = useState<boolean>(false);
    const [balanceData, setBalanceData] = useState<AccountBalanceInfo[] | undefined>(undefined);
    const [isError, setIsError] = useState<boolean>(false);
    const [tabValue, setTabValue] = useState(0);
    const [selectedHoldings, setSelectedHoldings] = useState<any[]>([]);
    const [childRefreshSignal, setChildRefreshSignal] = useState<number | any>(0);
    const [retirementDialogActive, setRetirementDialogActive] = useState(false);
    const { productsData } = useProductDataState();
    const { cortenApi, cortenAuthApi } = useCortenApiState();

    const {customTheme} = useLayoutState();
    const appConfigState = useAppConfigState();
    const user = useAuth();
    const { commodityDeskApi } = useCommodityDeskApiContext();
    

    const handleTabChange = (event: SyntheticEvent, newValue: number) => {
        // If we change to the Transactions Tab then reset the selected holdings
        if (newValue === 1) {
            setSelectedHoldings([]);
        }
        setTabValue(newValue);
    };

    useEffect(() => {
        fetchProducts(
            cortenApi,
            inventoryAccount.id,
            appConfigState.getProducts()
        ).then((foundProducts) => {
            const filteredProducts = foundProducts?.filter(
                (item: ProductDataRaw) =>
                    appConfigState.getProducts().map(product => product.id).includes(item.productId)
            );
            setProducts(filteredProducts);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleClientChange = (event: SelectChangeEvent<string>) => {
        const address = appConfigState.getAddressForClient(event.target.value)!;
        setAddressId(address);
        setBalanceData(undefined);
        setIsError(false);
        setValidAddressId(true);
        fetchAccountBalances(address);
        setSelectedHoldings([]);
    };

    const getAmount = (
        foundBalanceData: BalanceData[],
        amountType: string,
        productId: string
    ): string => {
        for (const foundBalance of foundBalanceData) {
            if (foundBalance.productId === productId) {
                return foundBalance[amountType as keyof ProductBalanceData]?.toString() ?? '0';
            }
        }
        return '0';
    };

    const fetchAccountBalances = async (searchAddressId: string) => {
        try {
            let listBalancesRequest: ListBalancesRequest = {
                accountId: [searchAddressId],
                sumProductItems: true
            }

            let foundBalanceData = await cortenApi.account.listBalances(listBalancesRequest);

            if (!foundBalanceData) {
                setIsError(true);
                throw new Error('Failed to fetch account balances');
            } else if (foundBalanceData.length === 0) {
                setBalanceData([]);
                return;
            }

            const accountBalanceData: AccountBalanceInfo[] = [];
            for (const product of products!) {
                accountBalanceData.push({
                    productCode: appConfigState.getProduct(product.productId)?.displayCode ?? product.data.code,
                    productName: appConfigState.getProduct(product.productId)?.displayName ?? product.data.name,
                    registryId: product.data.attributes[productAttributes.registry.key],
                    assignedAmount: getAmount(
                        foundBalanceData,
                        'assignedAmount',
                        product.productId
                    ),
                    unassignedAmount: getAmount(
                        foundBalanceData,
                        'unassignedAmount',
                        product.productId
                    ),
                    productId: product.productId
                });
            }
            setBalanceData(accountBalanceData);
        } catch (error) {
            setIsError(true);
            console.error('error', error);
        }
    };

    const openRetirementDialog = () => {
        setRetirementDialogActive(true);
    };

    const onHoldingSelectionUpdated = (holdings: any[]) => {
        setSelectedHoldings(holdings);
    };

    const onRetirementDialogClosed = () => {
        setSelectedHoldings([]);
        setChildRefreshSignal(childRefreshSignal + 1);
        setRetirementDialogActive(false);
        fetchAccountBalances(addressId);
    };

    return (
        <>
            
            <Typography variant='h2'>Client Management</Typography>

            {!products ? <LinearProgress /> :
                <>
                    <Box sx={{marginBottom: sectionSpace}}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={4}
                                sx={{ marginBottom: '0px' }}
                                style={{ marginBottom: '20px' }}
                            >
                                <Typography>Client</Typography>
                                <Select
                                    size="small"
                                    id="clientId"
                                    name="clientId"
                                    role="select"
                                    value={appConfigState.getClientForAddress(addressId) || ''}
                                    onChange={handleClientChange}
                                    sx={{ width: '10em' }} // Arbitrarily set based on the length of the client names
                                >
                                    {appConfigState.getClients().map((client) => (
                                        <MenuItem key={client.display} value={client.display}>
                                            {client.display}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Stack>

                        {!validAddressId && (
                            <Typography>
                                Please select a client
                            </Typography>
                        )}

                        {validAddressId && balanceData === undefined && !isError && (
                            <LinearProgress />
                        )}

                        {isError && <Typography>An error occurred.</Typography>}
                    </Box>

                    {!isError && balanceData !== undefined && (
                        <>

                            <Box sx={{marginBottom: sectionSpace}}>
                                <Typography variant='h3'>
                                    Balances
                                </Typography>

                                <TableContainer component={Paper} sx={{maxWidth: "50em"}}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{maxWidth: "1em"}}>Product Code</TableCell>
                                                <TableCell sx={{maxWidth: "4em"}}>Product Name</TableCell>
                                                <TableCell sx={{maxWidth: "1em"}}>Registry</TableCell>
                                                <TableCell sx={{maxWidth: "2em"}} align="right">Holdings</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            { balanceData.length > 0 ? balanceData.map((row: any, index: number) => (
                                                <TableRow hover key={'accountBalance' + index}>
                                                    <TableCell sx={{maxWidth: "1em"}}>{row.productCode}</TableCell>
                                                    <TableCell sx={{maxWidth: "4em"}}>{row.productName}</TableCell>
                                                    <TableCell sx={{maxWidth: "1em"}}>{row.registryId}</TableCell>
                                                    <TableCell sx={{maxWidth: "2em"}} align="right">
                                                        <AmountFormatWrapper
                                                            amount={row.assignedAmount}
                                                            minDecimalPos={productsData.get(row.productId)?.minDecimalPos!}
                                                            maxDecimalPos={productsData.get(row.productId)?.maxDecimalPos!} />
                                                    </TableCell>
                                                </TableRow>
                                            )) : appConfigState.getProducts().map((p) => (
                                                // Show zero balances for the default products if no balance data is available
                                                <TableRow hover key={'accountBalance' + p.id}>
                                                    <TableCell sx={{maxWidth: "1em"}}>{p.displayCode}</TableCell>
                                                    <TableCell sx={{maxWidth: "4em"}}>{p.displayName}</TableCell>
                                                    <TableCell sx={{maxWidth: "1em"}}>{productsData.get(p.id)?.attributes[productAttributes.registry.key]}</TableCell>
                                                    <TableCell sx={{maxWidth: "2em"}} align="right">
                                                        <AmountFormatWrapper
                                                        amount={0}
                                                        minDecimalPos={productsData.get(p.id)?.minDecimalPos!}
                                                        maxDecimalPos={productsData.get(p.id)?.maxDecimalPos!}/>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>

                            <Typography variant='h3'>
                                Holdings, Transactions and Forward Trades
                            </Typography>

                            <Button variant="outlined" onClick={openRetirementDialog}>Retire</Button>
                                {retirementDialogActive && (
                                    <HoldingRetirement
                                        useDialog={true}
                                        accountId={addressId}
                                        holdings={selectedHoldings}
                                        retirementDialogActive={retirementDialogActive}
                                        onRetirementDialogClosed={onRetirementDialogClosed}
                                        preSelectedProductId={undefined}
                                        preSelectedProjectId={undefined}
                                        preSelectedProjectType={undefined}
                                        preSelectedVintage={undefined}
                                        productsData={productsData}
                                        customTheme={customTheme}
                                        appConfigState={appConfigState}
                                        user={user}
                                        commodityDeskApi={commodityDeskApi}
                                        cortenApi={cortenApi}
                                        cortenAuthApi={cortenAuthApi}
                                    />
                                )}

                            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 1 }}>
                                <StyledTabs
                                    value={tabValue}
                                    onChange={handleTabChange}
                                    tabs={[
                                        { label: "Holdings", value: 0 },
                                        { label: "Transactions", value: 1 },
                                        { label: "Forward Trades", value: 2 }
                                    ]}
                                    customTheme={customTheme}
                                />
                            </Box>

                            {tabValue === 0 && (
                                <Holdings
                                    pageType={HoldingPageType.CLIENT}
                                    columnDefinitions={[
                                        {key: 'selectbox', showInDefaultColumnSet: [ColumnSet.All_Products, ColumnSet.Custom, ColumnSet.ACCU, ColumnSet.VCU, ColumnSet.LGC]},
                                        {key: 'holdingId', showInDefaultColumnSet: [ColumnSet.All_Products, ColumnSet.Custom, ColumnSet.ACCU, ColumnSet.VCU, ColumnSet.LGC, ColumnSet.MiQC]},
                                        {key: 'amount', showInDefaultColumnSet: [ColumnSet.All_Products, ColumnSet.Custom, ColumnSet.ACCU, ColumnSet.VCU, ColumnSet.LGC, ColumnSet.MiQC]},
                                        {key: 'productId', showInDefaultColumnSet: [ColumnSet.All_Products, ColumnSet.Custom, ColumnSet.ACCU, ColumnSet.VCU, ColumnSet.LGC, ColumnSet.MiQC]},
                                        {key: "projectType", showInDefaultColumnSet: [ColumnSet.ACCU, ColumnSet.VCU]},
                                        {key: 'projectId', showInDefaultColumnSet: [ColumnSet.ACCU, ColumnSet.VCU]},
                                        {key: "projectName", showInDefaultColumnSet: [ColumnSet.ACCU, ColumnSet.VCU]},
                                        {key: 'vintage', showInDefaultColumnSet: [ColumnSet.ACCU, ColumnSet.VCU]},
                                        {key: "projectState", showInDefaultColumnSet: [ColumnSet.ACCU]},
                                        {key: "country", showInDefaultColumnSet: [ColumnSet.VCU]},
                                        {key: "accreditationCode", showInDefaultColumnSet: [ColumnSet.LGC]},
                                        {key: "fuelSource", showInDefaultColumnSet: [ColumnSet.LGC]},
                                        {key: "creationYear", showInDefaultColumnSet: [ColumnSet.LGC]},
                                        {key: "generationYear", showInDefaultColumnSet: [ColumnSet.LGC]},
                                        {key: "generationState", showInDefaultColumnSet: [ColumnSet.LGC]},
                                        {key: "greenPowerAccredited", showInDefaultColumnSet: [ColumnSet.LGC]},
                                        {key: "facility", showInDefaultColumnSet: [ColumnSet.MiQC]},
                                        {key: "segment", showInDefaultColumnSet: [ColumnSet.MiQC]},
                                        {key: "issueMonth", showInDefaultColumnSet: [ColumnSet.MiQC]},
                                        {key: "issueYear"},
                                        {key: "countryOfOperation", showInDefaultColumnSet: [ColumnSet.MiQC]},
                                        {key: "certificateRegion"},
                                        {key: "operatorName", showInDefaultColumnSet: [ColumnSet.MiQC]},
                                        {key: "miqMethaneIntensity", showInDefaultColumnSet: [ColumnSet.MiQC]},
                                        {key: "miqGrade", showInDefaultColumnSet: [ColumnSet.MiQC]},
                                        {key: "miqGradeStatus", showInDefaultColumnSet: [ColumnSet.MiQC]},
                                        {key: "miqAuditorName"},
                                        {key: "eo100Grade", showInDefaultColumnSet: [ColumnSet.MiQC]},
                                        {key: 'state', showInDefaultColumnSet: [ColumnSet.MiQC]},
                                        {key: 'serialNumRange', showInDefaultColumnSet: [ColumnSet.All_Products, ColumnSet.Custom, ColumnSet.ACCU, ColumnSet.VCU]},
                                        {key: 'info', showInDefaultColumnSet: [ColumnSet.All_Products, ColumnSet.Custom, ColumnSet.ACCU, ColumnSet.VCU, ColumnSet.LGC, ColumnSet.MiQC]}
                                    ]}
                                    pageFilters={{accountId: addressId}}
                                    selectedHoldings={selectedHoldings}
                                    onHoldingSelectionUpdated={onHoldingSelectionUpdated}
                                    refreshSignal={childRefreshSignal}
                                />
                            )}
                            {tabValue === 1 && (
                                <Transactions
                                    accountId={addressId}
                                    products={products}
                                    refreshSignal={childRefreshSignal}
                                    cortenApi={cortenApi}
                                />
                            )}
                            {tabValue === 2 && (
                                <Holdings
                                    pageType={HoldingPageType.FORWARDS}
                                    columnDefinitions={[
                                        {key: 'tradeDate', showByDefault: false},
                                        {key: 'valueDate', showByDefault: true},
                                        {key: 'forwardId', showByDefault: true},
                                        {key: 'tradeId', showByDefault: true},
                                        {key: 'amount', showByDefault: true},
                                        {key: 'productId', showByDefault: true},
                                        {key: 'projectType', showByDefault: false},
                                        {key: 'projectId', showByDefault: false},
                                        {key: 'projectName', showByDefault: false},
                                        {key: 'vintage', showByDefault: false},
                                        {key: 'accreditationCode', showByDefault: false},
                                        {key: 'fuelSource', showByDefault: false},
                                        {key: 'creationYear', showByDefault: false},
                                        {key: 'generationYear', showByDefault: false},
                                        {key: 'generationState', showByDefault: false},
                                        {key: 'greenPowerAccredited', showByDefault: false},
                                        {key: 'facility', showByDefault: false},
                                        {key: 'segment', showByDefault: false},
                                        {key: 'issueMonth', showByDefault: false},
                                        {key: 'issueYear', showByDefault: false},
                                        {key: 'countryOfOperation', showByDefault: false},
                                        {key: 'certificateRegion', showByDefault: false},
                                        {key: 'operatorName', showByDefault: false},
                                        {key: 'miqMethaneIntensity', showByDefault: false},
                                        {key: 'miqGrade', showByDefault: false},
                                        {key: 'miqGradeStatus', showByDefault: false},
                                        {key: 'miqAuditorName', showByDefault: false},
                                        {key: 'eo100Grade', showByDefault: false},
                                        {key: 'currency', showByDefault: true},
                                        {key: 'price', showByDefault: true},
                                        {key: 'trader', showByDefault: false},
                                        {key: 'salesPerson', showByDefault: false},
                                        {key: 'salesCredits', showByDefault: false},
                                        {key: 'brokerName', showByDefault: false},
                                        {key: 'brokerage', showByDefault: false},
                                        {key: 'info', showByDefault: true}
                                    ]}
                                    defaultOrdering={`+${transactionAttributes.valueDate.key}`}
                                    pageFilters={{
                                        accountId: addressId,
                                        holdingState: 'Unassigned'
                                    }}
                                    selectedHoldings={[]}
                                    onHoldingSelectionUpdated={undefined}
                                    refreshSignal={childRefreshSignal}
                                />
                            )}
                        </>
                    )}
                </>
            }
        </>
    );
};

export default ClientManagement;
