import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Box, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useProjectDetailsState } from './state/ProjectDetailsState';
import { useTheme } from "@mui/material/styles";
import { carbonProjectAttributes, productAttributes, sectionSpace, useAppConfigState, getBeZeroRatingElement } from '@commodity-desk/common';

const ProjectAttributes = ({title, showVintage, vintage}:
    {
        title:string;
        showVintage: boolean;
        vintage: string;
    }) => {
    const { selectedProjectDetails, registryId, selectedIds} = useProjectDetailsState();
    const theme = useTheme();
    const appConfigState = useAppConfigState();

    const tableRowStyle = { '&:last-child td, &:last-child th': { border: 0 } };

    return (
        <Box sx={{ marginBottom: sectionSpace }}>
            <Typography variant='h3'>{title}</Typography>
            <TableContainer component={Paper} sx={{ minWidth: 750 }}>
                <Table sx={{ minWidth: 750 }} size='small' aria-label="project attributes table">
                    <TableBody>
                        <TableRow hover sx={tableRowStyle}>
                            <TableCell sx={{ width: 250 }}>
                                {'Product Code'}
                            </TableCell>
                            <TableCell>{ appConfigState.getProduct(selectedIds.current.productID)?.displayCode}</TableCell>
                        </TableRow>
                        <TableRow hover sx={tableRowStyle}>
                            <TableCell sx={{ width: 250 }}>
                                {'Product Name'}
                            </TableCell>
                            <TableCell>{ appConfigState.getProduct(selectedIds.current.productID)?.displayName}</TableCell>
                        </TableRow>
                        <TableRow hover sx={tableRowStyle}>
                            <TableCell sx={{ width: 250 }}>
                                {productAttributes.registry.display}
                            </TableCell>
                            <TableCell>{registryId}</TableCell>
                        </TableRow>
                        <TableRow hover sx={tableRowStyle}>
                            <TableCell>{carbonProjectAttributes.projectId.display}</TableCell>
                            <TableCell>{selectedProjectDetails?.projectId}</TableCell>
                        </TableRow>
                        <TableRow hover sx={tableRowStyle}>
                            <TableCell>{carbonProjectAttributes.projectName.display}</TableCell>
                            <TableCell>{selectedProjectDetails?.projectName}</TableCell>
                        </TableRow>
                        <TableRow hover sx={tableRowStyle}>
                            <TableCell>{carbonProjectAttributes.projectType.display}</TableCell>
                            <TableCell>{selectedProjectDetails?.projectType}</TableCell>
                        </TableRow>
                        <TableRow hover sx={tableRowStyle}>
                            <TableCell>{carbonProjectAttributes.country.display}</TableCell>
                            <TableCell>
                                {selectedProjectDetails?.country}
                            </TableCell>
                        </TableRow>
                        { selectedProjectDetails?.projectState && (
                            <TableRow hover sx={tableRowStyle}>
                                <TableCell>{carbonProjectAttributes.projectState.display}</TableCell>
                                <TableCell>
                                    {selectedProjectDetails?.projectState}
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow hover sx={tableRowStyle}>
                            <TableCell>{carbonProjectAttributes.projectUri.display}</TableCell>
                            <TableCell>
                                {selectedProjectDetails !== undefined && (
                                    <a
                                        href={selectedProjectDetails?.uri}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{
                                            color: theme.palette.text.primary
                                        }}
                                    >
                                        {selectedProjectDetails?.uri}
                                    </a>
                                )}
                            </TableCell>
                        </TableRow>

                        {appConfigState.getProduct(selectedIds.current.productID)?.displayCode === "VCU" && (
                            <TableRow hover sx={tableRowStyle}>
                                <TableCell>BeZero Rating</TableCell>
                                <TableCell>
                                    { getBeZeroRatingElement(selectedProjectDetails?.projectId) }
                                </TableCell>
                            </TableRow>
                        )}

                        {showVintage && (
                           <TableRow hover sx={tableRowStyle}>
                           <TableCell>{carbonProjectAttributes.vintage.display}</TableCell>
                           <TableCell>
                               {vintage}
                           </TableCell>
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ProjectAttributes;
