import { Controller, useForm, useWatch, } from 'react-hook-form';
import {
    Box,
    Button,
    Container,
    Grid,
    LinearProgress,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { 
    DATE_DISPLAY_FORMAT,
    ControlledTextField, 
    Currency,
    FormDrafts,
    inventoryAccount,
    Option,
    Options,
    ProductBase, 
    ProductItemFilterOptions,
    ProductItemForm,
    ProductType,
    ResettableFormFields, 
    sectionSpace,
    StorageItem,
    TradeDirection, 
    useAppConfigState,
    useFormDrafts,
    useProductDataState,
    useProductItemFilters,
    useRequired,
    useLayoutState,
    getBeZeroRating
} from '@commodity-desk/common';
import dayjs, { Dayjs } from 'dayjs';
import { ForwardTradeSubmitDialog } from './ForwardTradeSubmitDialog';
import { useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';

interface ForwardTradeForm {
    tradeId?: string,
    tradeDate: Dayjs,
    trader: string,
    counterparty: string,
    tradeDirection: TradeDirection,
    product: string,
    projectType: string,
    project: string,
    vintage: string,
    beZeroRating: string,
    projectState: string,
    country: string,
    // LGC
    fuelSource: string,
    creationYear: string,
    generationYear: string,
    generationState: string,
    greenPowerAccredited: string,
    // MiQC
    segment: string,
    issueYear: string,
    miqGrade: string,
    // other
    quantity: number,
    valueDateOption: string,
    customValueDate: Dayjs,
    currency: Currency,
    price: string,
    salesPerson: string,
    salesCredits: string,
    broker: string,
    brokerageFee: string,
}

const defaultValues: ForwardTradeForm = {
    tradeId: '',
    tradeDate: dayjs(),
    trader: '',
    counterparty: '',
    tradeDirection: TradeDirection.SELL,
    product: '',
    projectType: '',
    project: '',
    vintage: '',
    beZeroRating: '',
    projectState: '',
    country: '',
    // LGC
    fuelSource: '',
    creationYear: '',
    generationYear: '',
    generationState: '',
    greenPowerAccredited: '',
    // MiQC
    segment: '',
    issueYear: '',
    miqGrade: '',
    // other
    quantity: 0,
    valueDateOption: '',
    customValueDate: dayjs(),
    currency: Currency.AUD,
    price: '',
    salesPerson: '',
    salesCredits: '',
    broker: '',
    brokerageFee: '',
};

interface ForwardTradeDraft {
    form: ForwardTradeForm,
    filterOptions?: ProductItemFilterOptions,
    version: string
};

const valueDateOptions: Option[] = [1, 2, 3, 6, 9, 12, 18, 24, 36].map(
    (num) => new Option(`${num}`, `T + ${num} month${num === 1 ? '' : 's'}`)
).concat([new Option('Custom', 'Custom')]);

const ForwardTrade = () => {
    const {
        handleSubmit,
        control,
        getValues,
        resetField,
        reset,
        clearErrors,
        formState: { errors, isValid },
    } = useForm<ForwardTradeForm>({ mode: 'onChange', defaultValues: defaultValues });

    const balanceErrorWatch = useWatch({ name: ['tradeDirection', 'counterparty', 'product'], control });
    const formWatch = useWatch({ control });
    const { cortenApi } = useCortenApiState();

    const {
        productOptions,
        projectTypeOptions,
        projectOptions,
        vintageOptions,
        projectStateOptions,
        countryOptions,
        fuelSourceOptions,
        creationYearOptions,
        generationYearOptions,
        generationStateOptions,
        greenPowerAccreditedOptions,
        segmentOptions,
        issueYearOptions,
        miqGradeOptions,
        beZeroRatingOptions,
        onFilterChange,
        resetProductFilters,
        availableBalance,
    } = useProductItemFilters({cortenApi: cortenApi});

    const getBezeroBalance = (rating: string) => {
        const matchingOption = beZeroRatingOptions.values.find((value) => value.id === rating);
        if (matchingOption) {
            return matchingOption.balance;
        }
        return 50;
    }

    const beZeroRatingOptionsCustom = new Options(
        [
            new Option("AA", "AA", getBezeroBalance("AA"), false),
            new Option("A", "A", getBezeroBalance("A"), false),
            new Option("BBB", "BBB", getBezeroBalance("BBB"), false),
            new Option("BB", "BB", getBezeroBalance("BB"), false),
            new Option("B", "B", getBezeroBalance("B"), false),
            new Option("C", "C", getBezeroBalance("C"), false),
            new Option("D", "D", getBezeroBalance("D"), false)
        ],
        false
    );

    const {
        draftsMetadata,
        initialiseDrafts,
        selectDraft,
        updateDraft,
        deleteDraft,
        getCurrentDraftId,
    } = useFormDrafts<ForwardTradeDraft>(
        StorageItem.FORWARD_TRADE_FORM.key,
        { form: defaultValues },
        '3',
        () => { skipDraftUpdates.current++ }
    );
    const skipDraftUpdates = useRef(1); // set to "1" to skip update upon the load of default values

    const isSalesCreditsRequired = useRequired<ForwardTradeForm>('salesCredits', 'salesPerson', control, clearErrors);
    const isSalesPersonRequired = useRequired<ForwardTradeForm>('salesPerson', 'salesCredits', control, clearErrors);
    const isBrokerageFeeRequired = useRequired<ForwardTradeForm>('brokerageFee', 'broker', control, clearErrors);
    const isBrokerRequired = useRequired<ForwardTradeForm>('broker', 'brokerageFee', control, clearErrors);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [balanceError, setBalanceError] = useState<string>();
    const [isSubmitting, setSubmitting] = useState(false);

    const appConfigState = useAppConfigState();
    const { customTheme } = useLayoutState();
    const { productsData } = useProductDataState();

    const onSubmitOpen = (data: ForwardTradeForm) => {
        setSubmitting(true);
    };

    const onSubmitClose = (resetForm: boolean) => {
        setSubmitting(false);
        let currentDraftId = draftsMetadata!.currentDraftId;
        let draft = resetForm
            ? deleteDraft(currentDraftId, true)
            : selectDraft(currentDraftId);
        setFormFromDraft(draft);
    };

    const getFromAccountId = (form: ForwardTradeForm) : string => {
        let accountIdToUse = '';
        if (form.tradeDirection === TradeDirection.SELL) {
            accountIdToUse = inventoryAccount.id;
        } else {
            accountIdToUse = form.counterparty;
        }
        return accountIdToUse
    }

    // on mount - load form values from storage
    useEffect(() => {
        setFormFromDraft(initialiseDrafts());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // on form update - update stored values
    useEffect(() => {
        if (skipDraftUpdates.current > 0) {
            // ignore changes that are a result of switching drafts
            // to preserve the correct "modified date"
            skipDraftUpdates.current--;
        } else {
            updateDraft((data) => {
                data.form = formWatch as ForwardTradeForm;
                // We store dynamic options so that, when switching to a draft, we can show the previously
                // selected values immediately rather than waiting for a load to complete.
                // Current options will still be loaded in the background.
                data.filterOptions = {
                    productOptions: productOptions,
                    projectTypeOptions: projectTypeOptions,
                    projectOptions: projectOptions,
                    vintageOptions: vintageOptions,
                    beZeroRatingOptions: beZeroRatingOptions,
                    projectStateOptions: projectStateOptions,
                    countryOptions: countryOptions,
                    fuelSourceOptions: fuelSourceOptions,
                    creationYearOptions: creationYearOptions,
                    generationYearOptions: generationYearOptions,
                    generationStateOptions: generationStateOptions,
                    greenPowerAccreditedOptions: greenPowerAccreditedOptions,
                    segmentOptions: segmentOptions,
                    issueYearOptions: issueYearOptions,
                    miqGradeOptions: miqGradeOptions,
                };
            });
        }
    }, [formWatch]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let form = getValues();
        let error = undefined;
        if (form.tradeDirection === TradeDirection.BUY && !form.counterparty) {
            error = 'Please select a Counterparty';
        } else if (!form.product) {
            error = 'Please select a Product';
        }
        setBalanceError(error);
    }, [balanceErrorWatch]); // eslint-disable-line react-hooks/exhaustive-deps

    const getClientOptions = () => {
        let values = appConfigState.getClients()
            .map(client => (new Option(client.id, client.display)));
        return new Options(values);
    };

    const getCurrencyOptions = () => {
        let values = [
            new Option(Currency.AUD, Currency.AUD),
            new Option(Currency.USD, Currency.USD),
        ];
        return new Options(values);
    };

    const onOptionSelect = (field: keyof ProductItemForm) => {
        onFilterChange(buildResetCallback(), {...getValues(), account: getFromAccountId(getValues())}, field);
    };

    const handleDraftChange = (event: any, value: string) => {
        let draftId = value === 'ADD' ? null : value;
        setFormFromDraft(selectDraft(draftId));
    };

    const handleDraftDelete = (value: string) => setFormFromDraft(deleteDraft(value));

    const setFormFromDraft = (draft: ForwardTradeDraft) => {

        const accountIdToUse = getFromAccountId(draft.form);
        reset(draft.form, { keepDefaultValues: true });
        resetProductFilters(buildResetCallback(), {...draft.form, account:accountIdToUse}, draft.filterOptions);
    };

    const buildResetCallback = () => {
        let draftId = getCurrentDraftId();
        return (fieldName: keyof ResettableFormFields) => {
            // if the user hasn't switched drafts - reset form field
            if (draftId === getCurrentDraftId()) {
                resetField(fieldName);
                return;
            }
            // otherwise update the target draft
            updateDraft(data => data.form[fieldName] = defaultValues[fieldName] as any, draftId);
        };
    };

    const currentForm = getValues();
    const currentProduct = appConfigState.getProduct(currentForm.product);

    return (
        <form onSubmit={handleSubmit(onSubmitOpen)}>
            <Typography align={'center'} variant='h2'>Forward Trade Ticket</Typography>
            <Box display='flex' justifyContent={'center'} sx={{ mt: -2, mb: 2.5 }}>
                <FormDrafts draftsMetadata={draftsMetadata} onChange={handleDraftChange} onDelete={handleDraftDelete} customTheme={customTheme}/>
            </Box>
            <Container maxWidth='sm'>
                <Grid container spacing={2} mt={-1}>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12} marginBottom={-1.5}>
                            <Typography variant='h3'>Trade:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller name='tradeDate'
                                        control={control}
                                        rules={{
                                            required: 'Valid trade date is required',
                                            validate: value => value?.isValid() || 'Valid trade date is required',
                                        }}
                                        render={({ field, fieldState }) =>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-au'>
                                                <DatePicker label='Trade Date'
                                                            format={DATE_DISPLAY_FORMAT}
                                                            onChange={(date: any) => field.onChange(date)}
                                                            value={field.value}
                                                            inputRef={field.ref}
                                                            slotProps={{
                                                                textField: {
                                                                    fullWidth: true, size: 'small',
                                                                    required: true,
                                                                    error: !!fieldState?.error,
                                                                    helperText: fieldState?.error?.message,
                                                                },
                                                            }} />
                                            </LocalizationProvider>
                                        }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} display='inline-flex'>
                            <Typography margin={1}>Direction: </Typography>
                            <Controller name='tradeDirection'
                                        control={control}
                                        render={({ field }) =>
                                            <ToggleButtonGroup {...field}
                                                               onChange={(event, value) => {
                                                                   if (value == null) return;
                                                                   field.onChange(value);
                                                                   onOptionSelect('account');
                                                               }}
                                                               size='small' color='primary'
                                                               sx={{ height: '2.321rem' }}
                                                               exclusive fullWidth>
                                                <ToggleButton key={TradeDirection.SELL}
                                                              value={TradeDirection.SELL}>SELL</ToggleButton>
                                                <ToggleButton key={TradeDirection.BUY}
                                                              value={TradeDirection.BUY}
                                                              disabled>BUY</ToggleButton>
                                            </ToggleButtonGroup>} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ControlledTextField name='trader' label='Trader'
                                                 rules={{ required: 'Trader is required' }}
                                                 control={control} errors={errors} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ControlledTextField name='counterparty' label='Counterparty'
                                                 options={getClientOptions()}
                                                 customOnChange={() => onOptionSelect('counterparty' as keyof ProductItemForm)}
                                                 rules={{ required: 'Counterparty is required' }}
                                                 control={control} errors={errors} reset={resetField} />
                        </Grid>
                        <Grid item xs={12}>
                            <ControlledTextField name='tradeId' label='Trade ID'
                                                 rules={{
                                                    pattern: {
                                                        // eslint-disable-next-line no-useless-escape
                                                        value: /^[A-Za-z0-9\-_.~();\/?:@&=$#]+$/,
                                                        message: 'Trade ID can only contain alphanumeric characters and - _ . ~ ( ) ; / ? : @ & = $ #',
                                                    }
                                                 }}
                                                 control={control} errors={errors}/>
                        </Grid>
                    </Grid>

                    <Grid item height={sectionSpace}></Grid>

                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12} marginBottom={-1.5}>
                            <Typography variant='h3'>Product:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ControlledTextField
                                name='product' label='Product'
                                options={productOptions}
                                customOnChange={() => onOptionSelect('product')}
                                rules={{ required: 'Product is required' }}
                                ignoreOptionBalances
                                control={control} errors={errors} reset={resetField} />
                        </Grid>
                        {currentProduct?.productBase === ProductBase.Project && <Grid item xs={12}>
                            <ControlledTextField
                                name='projectType' label='Project Type'
                                options={projectTypeOptions}
                                customOnChange={() => onOptionSelect('projectType')}
                                ignoreOptionBalances
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos} />
                        </Grid>}
                        {currentProduct?.productBase === ProductBase.Project && <Grid item xs={12}>
                            <ControlledTextField
                                name='project' label='Project'
                                options={projectOptions}
                                customOnChange={() => onOptionSelect('project')}
                                ignoreOptionBalances
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}/>
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.VCU && <Grid item xs={12}>
                            <ControlledTextField
                                name='beZeroRating' label='BeZero Rating'
                                options={beZeroRatingOptionsCustom}
                                customOnChange={() => onOptionSelect('beZeroRating')}
                                ignoreOptionBalances
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}/>
                        </Grid>}
                        {currentProduct?.productBase === ProductBase.Project && <Grid item xs={12}>
                            <ControlledTextField
                                name='vintage' label='Vintage'
                                options={vintageOptions}
                                customOnChange={() => onOptionSelect('vintage')}
                                ignoreOptionBalances
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}/>
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.ACCU && <Grid item xs={12}>
                            <ControlledTextField
                                name='projectState' label='State'
                                options={projectStateOptions}
                                customOnChange={() => onOptionSelect('projectState')}
                                ignoreOptionBalances
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos} />
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.VCU && <Grid item xs={12}>
                            <ControlledTextField
                                name='country' label='Country'
                                options={countryOptions}
                                customOnChange={() => onOptionSelect('country')}
                                ignoreOptionBalances
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos} />
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.LGC && <Grid item xs={12}>
                            <ControlledTextField
                                name='fuelSource' label='Fuel Source'
                                options={fuelSourceOptions}
                                customOnChange={() => onOptionSelect('fuelSource')}
                                ignoreOptionBalances
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                            />
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.LGC && <Grid item xs={12}>
                            <ControlledTextField
                                name='creationYear' label='Creation Year'
                                options={creationYearOptions}
                                customOnChange={() => onOptionSelect('creationYear')}
                                ignoreOptionBalances
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                            />
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.LGC && <Grid item xs={12}>
                            <ControlledTextField
                                name='generationYear' label='Generation Year'
                                options={generationYearOptions}
                                customOnChange={() => onOptionSelect('generationYear')}
                                ignoreOptionBalances
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                            />
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.LGC && <Grid item xs={12}>
                            <ControlledTextField
                                name='generationState' label='Generation State'
                                options={generationStateOptions}
                                customOnChange={() => onOptionSelect('generationState')}
                                ignoreOptionBalances
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                            />
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.LGC && <Grid item xs={12}>
                            <ControlledTextField
                                name='greenPowerAccredited' label='GreenPower Accredited'
                                options={greenPowerAccreditedOptions}
                                customOnChange={() => onOptionSelect('greenPowerAccredited')}
                                ignoreOptionBalances
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                            />
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.MiQC && <Grid item xs={12}>
                            <ControlledTextField
                                name='segment' label='Segment'
                                options={segmentOptions}
                                customOnChange={() => onOptionSelect('segment')}
                                ignoreOptionBalances
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                            />
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.MiQC && <Grid item xs={12}>
                            <ControlledTextField
                                name='issueYear' label='Issue Year'
                                options={issueYearOptions}
                                customOnChange={() => onOptionSelect('issueYear')}
                                ignoreOptionBalances
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                            />
                        </Grid>}
                        {currentProduct?.displayCode === ProductType.MiQC && <Grid item xs={12}>
                            <ControlledTextField
                                name='miqGrade' label='MiQ Grade'
                                options={miqGradeOptions}
                                customOnChange={() => onOptionSelect('miqGrade')}
                                ignoreOptionBalances
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productsData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productsData.get(getValues().product)?.maxDecimalPos}
                            />
                        </Grid>}
                        <Grid item xs={12} mb={-4.5}>
                            <ControlledTextField name='quantity' label='Quantity' integer
                                rules={{
                                    required: 'Quantity is required',
                                    pattern: {
                                        value: /^\d*$/,
                                        message: 'Quantity must be a whole number',
                                    },
                                    min: {
                                        value: 1,
                                        message: 'Quantity must be greater than 0',
                                    }
                                }} control={control} errors={errors}/>
                            {availableBalance == null
                                ? (<LinearProgress sx={{ mt: 1.75, mb: 1.75, height: 8 }}/>)
                                : (<Box sx={{ mt: 1.75, mb: 1.75, height: 8 }}/>)}
                        </Grid>
                    </Grid>

                    <Grid item height={sectionSpace}></Grid>

                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12} marginBottom={-1.5}>
                            <Typography variant='h3'>Attributes:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={currentForm.valueDateOption === 'Custom' ? 6 : 12}>
                            <ControlledTextField name='valueDateOption' label='Value Date'
                                                 options={new Options(valueDateOptions)}
                                                 rules={{ required: 'Value Date is required' }}
                                                 control={control} errors={errors} reset={resetField} />
                        </Grid>
                        { currentForm.valueDateOption === 'Custom' && (
                            <Grid item xs={12} sm={6}>
                                <Controller name='customValueDate'
                                            control={control}
                                            rules={{
                                                required: 'Valid value date is required',
                                                validate: value => value?.isValid() || 'Valid value date is required',
                                            }}
                                            render={({ field, fieldState }) =>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-au'>
                                                    <DatePicker label='Value Date'
                                                                format={DATE_DISPLAY_FORMAT}
                                                                onChange={(date: any) => field.onChange(date)}
                                                                value={field.value}
                                                                inputRef={field.ref}
                                                                slotProps={{
                                                                    textField: {
                                                                        fullWidth: true, size: 'small',
                                                                        required: true,
                                                                        error: !!fieldState?.error,
                                                                        helperText: fieldState?.error?.message,
                                                                    },
                                                                }} />
                                                </LocalizationProvider>
                                            }
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6}>
                            <ControlledTextField name='currency' label='Currency'
                                                 options={getCurrencyOptions()}
                                                 rules={{ required: 'Currency is required' }}
                                                 control={control} errors={errors} reset={resetField} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ControlledTextField name='price' label='Price' type='number'
                                                 rules={{
                                                     required: 'Price is required',
                                                     min: {
                                                         value: 0,
                                                         message: 'Price must not be negative',
                                                     },
                                                 }}
                                                 control={control} errors={errors} reset={resetField} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ControlledTextField name='salesPerson' label='Sales Person'
                                                 rules={{ required: isSalesPersonRequired && 'Sales person is required' }}
                                                 control={control} errors={errors} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ControlledTextField name='salesCredits' label='Sales Credits' type='number'
                                                 rules={{
                                                     required: isSalesCreditsRequired && 'Sales credits are required',
                                                     min: {
                                                         value: 0,
                                                         message: 'Sales Credits must not be negative',
                                                     },
                                                 }}
                                                 control={control} errors={errors} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ControlledTextField name='broker' label='Broker'
                                                 rules={{ required: isBrokerRequired && 'Broker is required' }}
                                                 control={control} errors={errors} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ControlledTextField name='brokerageFee' label='Brokerage Fee' type='number'
                                                 rules={{
                                                     required: isBrokerageFeeRequired && 'Brokerage fee is required',
                                                     min: {
                                                         value: 0,
                                                         message: 'Brokerage Fee must not be negative',
                                                     },
                                                 }}
                                                 control={control} errors={errors} />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} mt={4}>
                        <Button disabled={!isValid}
                                type='submit'
                                variant='outlined'
                                color='primary'
                                fullWidth>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Container>
            <ForwardTradeSubmitDialog open={isSubmitting}
                                      onClose={onSubmitClose}
                                      form={getValues()}
                                      projectName={projectOptions?.values.find(opt => opt.id === getValues().project)?.label} />
        </form>
    );
};

export { ForwardTrade, TradeDirection, type ForwardTradeForm };
