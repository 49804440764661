import { LinearProgress } from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridColumnVisibilityModel,
    GridFilterItem,
    GridFilterModel,
    GridPaginationModel,
    GridSortModel,
    GridToolbar,
} from '@mui/x-data-grid';
import { useEffect, useRef, useState } from 'react';
import { filterOperators } from '../utility/DataGridFilterUtil';
import { ForwardTradeDeliverDialog } from '../view/trade/forward/ForwardTradeDeliverDialog';
import { ForwardTradeCancelDialog } from '../view/trade/forward/ForwardTradeCancelDialog';
import { 
    ColumnSet,
    getStoredColumnSet,
    getStoredColumnVisibilityModel,
    productBasedColumnSets,
    storeColumnVisibilityModel,
    TableColumnDefinition,
    useGridToolbarWithColumnSelector
} from '../utility/ColumnSetSelectorUtil';
import { 
    ActionType,
    AttributeGroup,
    adjustFiltersForSort,
    currentDateExportFormat,
    isBlankFilterItem,
    ensureArray,
    fetchTransactionsByProductItem,
    getComparedFilterModel,
    getDataGridColumnDefinitions,
    getFieldDefinitions,
    getFieldDefinitionMap,
    getCoreFilterValue,
    getProductAttributeUiElements,
    findFieldDefinitionInMapEnforced,
    getSortItemQueryStrings,
    getTableColumnSetStorageItem,
    inventoryAccount,
    isTrade,
    lgcAttributes,
    miqcAttributes,
    productAttributes,
    ProductType,
    carbonProjectAttributes,
    transactionAttributes,
    TransactionOverview,
    TransactionType,
    useAppConfigState,
    useLayoutState,
    useProductDataState,
    NullableString,
    getBeZeroRating
} from '@commodity-desk/common';
import { STORAGE_KEY_PREFIX } from '../state/Variables';
import { ListBalanceHistoryRequest, ListProductItemsRequest, equalsExpr, timeRangeExpr } from '@trovio-tech/trovio-core-api-js';
import { muiToCoreFilter, useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';

enum TradePageType {
    TradeBlotter = 'TradeBlotter',
    Forwards = 'Forwards'
}

interface TradeRow {
    transactionTimestamp: string;
    tradeDate: string;
    tradeId: string;
    transactionId: string;
    forwardId: string;
    transactionType: TransactionType | undefined;
    counterParty: string;
    counterPartyToolTipText: string;
    productId: string;
    registry: string;
    projectType: string;
    projectId: string;
    projectName: string;
    beZeroRating?: string;
    vintage: string;
    country: string;
    projectState?: string;
    accreditationCode: string;
    fuelSource: string;
    generationYear: string;
    creationYear: string;
    generationState: string;
    greenPowerAccredited: string;
    facility: string;
    segment: string;
    issueMonth: string;
    issueYear: string;
    countryOfOperation: string;
    certificateRegion: string;
    operatorName: string;
    miqMethaneIntensity: string;
    miqGrade: string;
    miqGradeStatus: string;
    miqAuditorName: string;
    eo100Grade: string;
    quantity: number;
    currency: string;
    valueDate: string;
    price?: number;
    trader?: string;
    salesPerson?: string;
    salesCredits?: number;
    brokerName?: string;
    brokerage?: number;
}

enum ForwardTradeState {
    Initialisation = 'Initialisation',
    CheckingProductItem = 'CheckingProductItem',
    AlreadyDelivered = 'AlreadyDelivered',
    AlreadyCanceled = 'AlreadyCanceled',
    AvailableForDeliveryOrCancelation = 'AvailableForDeliveryOrCancelation',
    ConfirmingToDeliver = 'ConfirmingToDeliver',
    ConfirmingToCancel = 'ConfirmingToCancel',
    // Subsequent states are managed by ForwardTradeDeliverDialog or ForwardTradeCancelDialog
}

interface TradePageFilters {
    product?: NullableString;
    projectType?: NullableString;
    projectId?: NullableString;
    beZeroRating?: NullableString;
    vintage?: NullableString;
    country?: NullableString;
    projectState?: NullableString;
    accreditationCode?: NullableString;
    fuelSource?: NullableString;
    generationYear?: NullableString;
    creationYear?: NullableString;
    generationState?: NullableString;
    greenPowerAccredited?: NullableString;
    segment?: NullableString;
    issueYear?: NullableString;
    miqGrade?: NullableString;
    facility?: NullableString;
    valueDateStart?: NullableString;
    valueDateEnd?: NullableString;
}

const shortenAddress = (address: string): string => {
    if (address === undefined) return address;
    if (address.length > 10) {
        return `${address.slice(0, 8)}...${address.slice(address.length - 6, address.length)}`;
    }
    return address;
};

// We currently use fixed table height with a fixed number of items per page
const PAGE_SIZE = 25;
const INITIAL_PAGINATION_MODEL = { page: 0, pageSize: PAGE_SIZE };

/**
 * A general type of trade table, that includes any kind of trade including physical trades, forward
 * trades, and retirements. Also includes the option to show data in a format specific to forward
 * trades (by product item) that can display the forward ID
 *
 * @param pageType           The type of page that will be displaying these trades. Affects the type
 *                           of data in the table (i.e. transactions or product items)
 * @param columnDefinitions  The set of columns that are available, along with whether each one should
 *                           be shown by default or not, and the order that they should be displayed in.
 * @param defaultOrdering    The default ordering that should apply to the table rows
 * // Filters which apply at the whole page level to restrict the data shown in the table. Cannot be changed.
 * @param pageFilters        Apply page level filtering on a variety of attributes
 * @returns
 */
const Trades = ({
    pageType,
    columnDefinitions,
    defaultOrdering,
    pageFilters,
}: {
    pageType: TradePageType,
    columnDefinitions: TableColumnDefinition[],
    defaultOrdering?: string,
    pageFilters: TradePageFilters,
}) => {

    /**
     * Get the table name component to be used in the column visibility model storage item's key.
     * If page type is TradePageType.Forwards, return {TradePageType.Forwards + productId}
     * Else return TradePageType
     * @returns
     */
    const getTableNameForStorageKey = () => {
        let tableName: string = pageType;
        if (pageType === TradePageType.Forwards && pageFilters.product) {
            tableName = `${pageType}-${pageFilters.product}`;
        }
        return tableName;
    };

    const [rows, setRows] = useState<TradeRow[]>([]);
    const abortController = useRef(new AbortController());
    const [rowCount, setRowCount] = useState<number>(0);
    const mapPageToNextCursor = useRef<{ [page: number]: string | undefined }>({});
    const [paginationModel, setPaginationModel] = useState(INITIAL_PAGINATION_MODEL);
    const [filterModel, setFilterModel] = useState({ items: [] } as GridFilterModel);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [chosenColumnSet, setChosenColumnSet] = useState<ColumnSet>(getStoredColumnSet(getTableNameForStorageKey));
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(getStoredColumnVisibilityModel(getStoredColumnSet(getTableNameForStorageKey), columnDefinitions, getTableNameForStorageKey));
    const [nextPage, setNextPage] = useState<any>(null);
    const [selectedRow, setSelectedRow] = useState<TradeRow | undefined>(undefined); // State to track selected row
    const [dialogActionsTaken, setDialogActionsTaken] = useState(false);
    const [forwardTradeState, setForwardTradeState] = useState<ForwardTradeState>(ForwardTradeState.Initialisation);
    const [pageLoading, setPageLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const appConfigState = useAppConfigState();
    const { productsData } = useProductDataState();
    const { cortenApi } = useCortenApiState();
    const { customTheme } = useLayoutState();

    const fieldDefinitions = getFieldDefinitions({appConfigState: appConfigState, productsData: productsData, filterOperators: filterOperators});
    const fieldDefinitionMap = getFieldDefinitionMap({appConfigState: appConfigState, productsData: productsData, filterOperators: filterOperators});

    const handleColumnSetChange = (event: any) => {
        const columnSet = event.target.value;
        setChosenColumnSet(columnSet);
        // save column selection in local storage
        const storageItem = getTableColumnSetStorageItem(getTableNameForStorageKey(), STORAGE_KEY_PREFIX);
        storageItem.setItemValue(columnSet);
        // reset filters and sort
        setFilterModel({ items: [] } as GridFilterModel);
        setSortModel([]);
        // refresh table data. Update column visibility only after fetching, to avoid display issue.
        refreshTable({
            filterItems: [],
            sortItems: [],
            columnSet: columnSet,
        });
    };

    const {
        ExtendedToolbarWithColumnSelector
    } = useGridToolbarWithColumnSelector({
        customTheme,
        chosenColumnSet,
        handleColumnSetChange
    });

    useEffect(() => {
        abortController.current.abort();
        abortController.current = new AbortController();
        fetchTransactions({abortSignal: abortController.current.signal}).then(() => {
            setPageLoading(false);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!tableLoading && nextPage) {
            mapPageToNextCursor.current[paginationModel.page] = nextPage;
        }
    }, [tableLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    const handlePaginationModelChange = async (newPaginationModel: GridPaginationModel) => {
        if (tableLoading) {
            return; // Since we use block based pagination we cannot skip ahead to future pages, we need to wait for the current page to load
        }
        if (
            newPaginationModel.page === 0 ||
            mapPageToNextCursor.current[newPaginationModel.page - 1]
        ) {
            setTableLoading(true);
            abortController.current.abort();
            abortController.current = new AbortController();
            setPaginationModel(newPaginationModel);
            await fetchTransactions({
                nextPage: mapPageToNextCursor.current[newPaginationModel.page - 1],
                filterItems: filterModel.items,
                sortItems: sortModel,
                abortSignal: abortController.current.signal
            });
        }
    };

    const handleFilterModelChange = async (newFilterModel: GridFilterModel) => {
        const comparedFilterModel = getComparedFilterModel(filterModel, newFilterModel);
        setFilterModel(comparedFilterModel);
        // Reset the pagination model since we are now applying filter which will have its own pages
        setPaginationModel(INITIAL_PAGINATION_MODEL);
        // refresh data
        refreshTable({
            filterItems: comparedFilterModel.items,
            sortItems: sortModel,
        });
    };

    const handleSortModelChange = async (newSortModel: GridSortModel) => {
        setSortModel(newSortModel);
        // Reset the pagination model due to applying a new sorting
        setPaginationModel(INITIAL_PAGINATION_MODEL);
        // refresh data
        refreshTable({
            filterItems: filterModel.items,
            sortItems: newSortModel,
        });
    };

    const refreshTable = async ({
        filterItems,
        sortItems,
        columnSet = undefined
    }: {
        filterItems: GridFilterItem[];
        sortItems?: GridSortModel;
        columnSet?: ColumnSet;
    }) => {
        setPaginationModel(INITIAL_PAGINATION_MODEL);
        setTableLoading(true);
        abortController.current.abort();
        abortController.current = new AbortController();
        await fetchTransactions({
            filterItems: filterItems,
            sortItems: sortItems,
            columnSet: columnSet ?? chosenColumnSet,
            abortSignal: abortController.current.signal
        }).then(r => {
            if (columnSet) {
                const customColumnVisibilityModel = getStoredColumnVisibilityModel(columnSet, columnDefinitions, getTableNameForStorageKey);
                setColumnVisibilityModel(customColumnVisibilityModel);
            }
        });
    }

    const fetchTransactions = async ({
        nextPage,
        filterItems = [],
        sortItems = [],
        columnSet = undefined,
        abortSignal
    }: {
        nextPage?: string;
        filterItems?: GridFilterItem[];
        sortItems?: GridSortModel; // GridSortModel is an array of GridSortItems
        columnSet?: ColumnSet;
        abortSignal: AbortSignal;
    }) => {
        try {
            let isProductItemApiQuery = false;

            let currentColumnSet = columnSet ?? chosenColumnSet;
            let adjustedFilterItems = adjustFiltersForSort({
                filterItems: filterItems,
                sortItems: sortItems,
                pageFilters: [],
                fieldDefinitionMap: fieldDefinitionMap
            });

            let transactionTypeFilterApplied = false;
            let productFilterApplied = false;
            let response;

            if (pageType === TradePageType.TradeBlotter) {
                let listBalanceHistoryRequest: ListBalanceHistoryRequest = {
                    transactionType: "TransferRequest,BakeHoldingRequest,CreateProductItemRequest,AssignHoldingRequest",
                    transactionAttribute: {},
                    productItemAttribute: {},
                    unassignedAmount: "0.."
                };
                if (!pageFilters.product) {
                    if (productBasedColumnSets.includes(currentColumnSet)) {
                        listBalanceHistoryRequest.productId = [appConfigState.getProducts().filter((data) => data.displayCode === currentColumnSet).map(data => data.id)[0]];
                        productFilterApplied = true;
                    }
                }
                adjustedFilterItems.filter(item => !isBlankFilterItem(item)).forEach((item) => {
                    switch (item.field) {
                        case 'transactionTimestamp':
                            listBalanceHistoryRequest.timestamp = muiToCoreFilter({item: item, isDate: true, containsTime: true}) as string;
                            break;
                        case 'transactionId':
                            const transactionIdFilter = muiToCoreFilter({ item: item });
                            listBalanceHistoryRequest.transactionId = ensureArray(transactionIdFilter);
                            break;
                        case 'counterParty':
                            const counterpartyFilter = muiToCoreFilter({ item: item });
                            listBalanceHistoryRequest.accountId = ensureArray(counterpartyFilter);
                            break;
                        case 'productId':
                            const productIdFilter = muiToCoreFilter({ item: item });
                            listBalanceHistoryRequest.productId = ensureArray(productIdFilter)
                            productFilterApplied = true;
                            break;
                        case 'quantity':
                            listBalanceHistoryRequest.amount = muiToCoreFilter({ item: item, unitAmount: 1 }) as string;
                            break;
                        default:
                            // Any attribute based filter logic goes here
                            const filterField = findFieldDefinitionInMapEnforced(item.field, fieldDefinitionMap);
                            const filterValue = getCoreFilterValue(item, filterField);
                            if (filterField.attribute?.group === AttributeGroup.TRADE) {
                                listBalanceHistoryRequest.transactionAttribute![filterField.attribute?.key] = filterValue;
                            } else if (filterField.attribute?.group === AttributeGroup.PROJECT) {
                                listBalanceHistoryRequest.productItemAttribute![filterField.attribute?.key] = filterValue;
                            } else if (filterField.attribute?.group === AttributeGroup.SHARED) {
                                if (item.field === 'transactionType') {
                                    listBalanceHistoryRequest.transactionAttribute![filterField.attribute?.key] = filterValue;
                                    transactionTypeFilterApplied = true;
                                } else {
                                    throw new Error(`Field ${item.field} was configured for filtering using a SHARED attribute but it was not specified whether this should be a product item or transaction based attribute.`);
                                }
                            } else {
                                throw new Error(`Unknown attribute group ${filterField.attribute?.group} for attribute used in filter`);
                            }
                    }
                });
                listBalanceHistoryRequest.sort = getSortItemQueryStrings({
                    items: sortItems,
                    defaultSort: defaultOrdering,
                    appConfigState: appConfigState,
                    isProductItemApiQuery: isProductItemApiQuery,
                    fieldDefinitionMap: fieldDefinitionMap
                });
                if (!transactionTypeFilterApplied) {
                    listBalanceHistoryRequest.transactionAttribute![transactionAttributes.transactionTypeInventory.key] = [
                        TransactionType.PhysicalBuy,
                        TransactionType.PhysicalSell,
                        TransactionType.ForwardSell,
                        TransactionType.InventoryRetirement,
                        TransactionType.ClientRetirement,
                        TransactionType.LockToMarket,
                        TransactionType.ForwardSellDeliver
                    ];
                }
                if (!productFilterApplied) {
                    listBalanceHistoryRequest.productId = appConfigState
                    .getProducts()
                    .map((p) => p.id);
                }

                listBalanceHistoryRequest.groupBy = fieldDefinitions.filter(
                    // Group by all fields that have an attribute defined, and that we have not explicitly excluded from the group-by
                    fieldDefinition => fieldDefinition.attribute !== undefined && fieldDefinition.includeInGroupBy !== false
                ).filter(
                    // If we are using a product based column set like ACCU, VCU etc, meaning we only show that product data on this page,
                    // and the current field is defined to only be used with specific products, and one of these products matches the currently
                    // selected product in the column set dropdown, then include it in the group-by. This is a performance optimisation.
                    fieldDefinition => !productBasedColumnSets.includes(currentColumnSet)
                        || !fieldDefinition.productTypes
                        || fieldDefinition.productTypes.includes(currentColumnSet as unknown as ProductType)
                ).map(fieldDefinition => {
                    if (fieldDefinition.attribute!.group === 'PROJECT') {
                        return `productItemAttribute.${fieldDefinition.attribute!.key}`;
                    } else if (fieldDefinition.attribute!.group === 'TRADE') {
                        return `transactionAttribute.${fieldDefinition.attribute!.key}`;
                    } else if (fieldDefinition.attribute!.group === 'SHARED') {
                        if (fieldDefinition.key === 'transactionTypeInventory') {
                            // Include TRANSACTION_TYPE_INVENTORY in the groupBy, which is used to avoid merging forward holding
                            // deflation records with the corresponding physical transfer record for forward trade delivery.
                            // This works because forward delivery consists of 3 events for the transaction:
                            // (1) Physical inventory product items are transferred to the forward product item
                            // (2) The forward product item is deflated
                            // (3) The forward product item is deleted
                            // Events (1) and (2) can be grouped together by the /api/pub/history API if they have exactly the same attributes,
                            // since they are part of the same transaction, and their amounts cancel out (e.g. +3 vs -3). We avoid this situation
                            // by ensuring they have different attributes. This is done by adding a group by on the TRANSACTION_TYPE_INVENTORY
                            // attribute as a product item attribute. This attribute is always present on the forward trade product item, with
                            // the value 'ForwardSell'. However, we never set this attribute on the physical product item. When this attribute
                            // is part of the group by, it ensures that these two events are never merged and left out of the results.
                            return `productItemAttribute.${fieldDefinition.attribute!.key}`;
                        } else {
                            throw new Error(`Field ${fieldDefinition.key} was configured for the groupBy using a SHARED attribute but it was not specified whether this should be a product item or transaction based attribute.`);
                        }
                    } else {
                        throw new Error(`Unknown attribute group ${fieldDefinition.attribute!.group} for attribute used in groupBy`);
                    }
                }).concat([
                    `transaction`
                ]).filter(function(item, idx, self) {
                    return self.indexOf(item) === idx; // Removes any duplicates
                });

                listBalanceHistoryRequest.pageFrom = nextPage;
                listBalanceHistoryRequest.pageWithCount = true;
                listBalanceHistoryRequest.pageLimit = PAGE_SIZE;
                response = cortenApi.account.listBalanceHistory(listBalanceHistoryRequest, { ...(abortSignal && { signal: abortSignal })});

            } else if (pageType === TradePageType.Forwards) {
                let listProductItemsRequest : ListProductItemsRequest = {
                    productId: pageFilters.product as string,
                    isUnassigned: true,
                    attributes: {}
                }
                adjustedFilterItems.filter(item => !isBlankFilterItem(item)).forEach((item) => {
                    switch (item.field) {
                        case 'productId':
                            listProductItemsRequest.productId = muiToCoreFilter({ item: item }) as string;
                            productFilterApplied = true;
                            break;
                        default:
                            const filterField = findFieldDefinitionInMapEnforced(item.field, fieldDefinitionMap);
                            listProductItemsRequest.attributes![filterField.attribute!.key] = getCoreFilterValue(item, filterField);
                            if (item.field === 'transactionType') {
                                transactionTypeFilterApplied = true;
                            }
                    }
                });

                if (pageFilters.projectType !== undefined) {
                    listProductItemsRequest.attributes![carbonProjectAttributes.projectType.key] = `${equalsExpr(pageFilters.projectType)}`;
                }
                if (pageFilters.projectId !== undefined) {
                    listProductItemsRequest.attributes![carbonProjectAttributes.projectId.key] = `${equalsExpr(pageFilters.projectId)}`;
                }
                if (pageFilters.beZeroRating !== undefined) {
                    listProductItemsRequest.attributes![carbonProjectAttributes.beZeroRating.key] = `${equalsExpr(pageFilters.beZeroRating)}`;
                }
                if (pageFilters.vintage !== undefined) {
                    listProductItemsRequest.attributes![carbonProjectAttributes.vintage.key] = `${equalsExpr(pageFilters.vintage)}`;
                }
                if (pageFilters.country !== undefined) {
                    listProductItemsRequest.attributes![carbonProjectAttributes.country.key] = `${equalsExpr(pageFilters.country)}`;
                }
                if (pageFilters.projectState !== undefined) {
                    listProductItemsRequest.attributes![carbonProjectAttributes.projectState.key] =  `${equalsExpr(pageFilters.projectState)}`;
                }
                if (pageFilters.accreditationCode !== undefined) {
                    listProductItemsRequest.attributes![lgcAttributes.accreditationCode.key] =  `${equalsExpr(pageFilters.accreditationCode)}`;
                }
                if (pageFilters.fuelSource !== undefined) {
                    listProductItemsRequest.attributes![lgcAttributes.fuelSource.key] =  `${equalsExpr(pageFilters.fuelSource)}`;
                }
                if (pageFilters.generationYear !== undefined) {
                    listProductItemsRequest.attributes![lgcAttributes.generationYear.key] =  `${equalsExpr(pageFilters.generationYear)}`;
                }
                if (pageFilters.creationYear !== undefined) {
                    listProductItemsRequest.attributes![lgcAttributes.creationYear.key] = `${equalsExpr(pageFilters.creationYear)}`;
                }
                if (pageFilters.generationState !== undefined) {
                    listProductItemsRequest.attributes![lgcAttributes.generationState.key] =  `${equalsExpr(pageFilters.generationState)}`;
                }
                if (pageFilters.greenPowerAccredited !== undefined && pageFilters.greenPowerAccredited !== "") {
                    listProductItemsRequest.attributes![lgcAttributes.greenPowerAccredited.key] =  `${equalsExpr(pageFilters.greenPowerAccredited)}`;
                }
                if (pageFilters.segment !== undefined) {
                    listProductItemsRequest.attributes![miqcAttributes.segment.key] = `${equalsExpr(pageFilters.segment)}`;
                }
                if (pageFilters.issueYear !== undefined) {
                    listProductItemsRequest.attributes![miqcAttributes.issueYear.key] = `${equalsExpr(pageFilters.issueYear)}`;
                }
                if (pageFilters.miqGrade !== undefined) {
                    listProductItemsRequest.attributes![miqcAttributes.miqGrade.key] = `${equalsExpr(pageFilters.miqGrade)}`;
                }
                if (pageFilters.facility !== undefined) {
                    listProductItemsRequest.attributes![miqcAttributes.facility.key] = `${equalsExpr(pageFilters.facility)}`;
                }
                if (pageFilters.valueDateStart && pageFilters.valueDateEnd) {
                    listProductItemsRequest.attributes![transactionAttributes.valueDate.key] = timeRangeExpr(
                        new Date(`${pageFilters.valueDateStart}`),
                        new Date(`${pageFilters.valueDateEnd}`)
                    );
                }

                listProductItemsRequest.sort = getSortItemQueryStrings({
                    items: sortItems,
                    defaultSort: defaultOrdering,
                    appConfigState: appConfigState,
                    isProductItemApiQuery: true,
                    fieldDefinitionMap: fieldDefinitionMap
                });

                listProductItemsRequest.pageFrom = nextPage;
                listProductItemsRequest.pageWithCount = true;
                listProductItemsRequest.pageLimit = PAGE_SIZE;

                response = cortenApi.productInventory.listProductItems(listProductItemsRequest, { ...(abortSignal && { signal: abortSignal }) });
                isProductItemApiQuery = true;
            } else {
                console.error("Unexpected Trade Page Type");
            }
            const responseJson = await response;
            const derivedRows: TradeRow[] = [];

            responseJson!.list.forEach((data: any) => {
                const newRow: TradeRow = transformToRow(data, isProductItemApiQuery);
                derivedRows.push(newRow);
            });

            setRows(derivedRows);
            setRowCount(responseJson!.count!);
            setNextPage(responseJson?.nextPage);
            setTableLoading(false);
            setPageLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * Function to calculate the weight of a particular value for transactions that involve
     * multiple different project vintage combinations. Currently only used for salesCredits
     * and Brokerage.
     *
     * @param totalValue The value to calculate weight for
     * @param weightedAmount The Amount to use for calculation of the weight
     * @param totalAmount
     *
     * @returns A weighted value of the totalValue based on the amounts or undefined
     */
    const getWeightedValue = ({
        totalvalue,
        weightedAmount,
        totalAmount
    }: {
        totalvalue: number | undefined;
        weightedAmount: number;
        totalAmount: number;
    }): number | undefined => {

        // If the total value in the request is already undefined to begin with then no need
        // to calculate anything
        if (totalvalue === undefined) {
            return totalvalue;
        }

        // Calculate the percentage weight of the value based on the amount associated with this
        // transfer vs the total amount in the transaction
        return (weightedAmount / totalAmount) * (totalvalue as number);
    };

    const transformToRow = (data: any, isProductItemApiQuery: boolean): TradeRow => {
        let rowProductItemAttributes: any;
        let rowTransactionAttributes: any;
        if (isProductItemApiQuery) {
            rowProductItemAttributes = data.productItem.data.attributes;
            rowTransactionAttributes = data.productItem.data.attributes;
        } else {
            rowProductItemAttributes = data.productItemAttributes;
            rowTransactionAttributes = data.transaction.request.attributes;
        }

        let quantity = 0;
        if (isProductItemApiQuery) {
            quantity = data.unassignedAmount;
        } else {
            quantity = data.balances.amount;
        }

        let transactionType: TransactionType | undefined;
        if (isProductItemApiQuery) {
            // Currently we only ever use product item queries to fetch forward sell trades.
            transactionType = TransactionType.ForwardSell;
        } else {
            switch (data.transaction.request.type) {
                case 'TransferRequest':
                    transactionType = data.transaction.request.fromAccountId === inventoryAccount.id ? TransactionType.PhysicalSell : TransactionType.PhysicalBuy;
                    break;
                case 'BakeHoldingRequest':
                    transactionType = data.transaction.request.fromAccountId === inventoryAccount.id ? TransactionType.InventoryRetirement : TransactionType.ClientRetirement;
                    break;
                case 'CreateProductItemRequest':
                    // All should be isUnassigned=True. False would be for physical creation rows, which should be filtered out above
                    transactionType = TransactionType.ForwardSell;
                    break;
                case 'AssignHoldingRequest':
                    transactionType = TransactionType.ForwardSellDeliver;
                    break;
            }
        }

        let transactionTimestamp;
        let tradeDate;
        let tradeId;
        let transactionId;
        let forwardId;
        let counterParty;
        let productId;
        let brokerage;
        let salesCredits;
        let currency;
        let valueDate;
        let price;
        let trader;
        let salesPerson;
        let brokerName;

        if (isTrade(transactionType)) {
            if (isProductItemApiQuery) {
                transactionTimestamp = rowTransactionAttributes?.[transactionAttributes.tradeDate.key];
                productId = data.productItem.data.productId;
                transactionId = data.productItem.productItemId;
                forwardId = data.productItem.productItemId;
            } else {
                transactionTimestamp = data.transaction.timestamp;
                productId = data.balances.productId;
                transactionId = data.transaction.txId;
                if (transactionType === TransactionType.ForwardSell) {
                    forwardId = data.transaction.blockAndIndex;
                }
            }
            tradeId = rowTransactionAttributes?.[transactionAttributes.tradeId.key];

            counterParty = isProductItemApiQuery
                ? data.productItem.data.toAccountId
                : transactionType === TransactionType.ForwardSell
                ? data.transaction.request.toAccountId
                : transactionType === TransactionType.PhysicalSell
                ? data.transaction.request.outputList[0].toAccountId
                : data.transaction.request.fromAccountId;

            let amount = isProductItemApiQuery
                ? quantity
                : transactionType === TransactionType.ForwardSell
                ? data.transaction.request.initialAmount
                : transactionType === TransactionType.ForwardSellDeliver
                ? data.balances.amount
                : data.transaction.request.outputList[0].from.amount;

            brokerage = getWeightedValue({
                totalvalue: rowTransactionAttributes?.[transactionAttributes.brokerage.key],
                weightedAmount: quantity,
                totalAmount: parseFloat(amount)
            });

            salesCredits = getWeightedValue({
                totalvalue: rowTransactionAttributes?.[transactionAttributes.salesCredits.key],
                weightedAmount: quantity,
                totalAmount: parseFloat(amount)
            });

            const maybeTradeDate = rowTransactionAttributes?.[transactionAttributes.tradeDate.key];
            if (maybeTradeDate) {
                tradeDate = maybeTradeDate;
            }

            currency = rowTransactionAttributes?.[transactionAttributes.currency.key];
            valueDate = rowTransactionAttributes?.[transactionAttributes.valueDate.key];
            price = rowTransactionAttributes?.[transactionAttributes.price.key];
            trader = rowTransactionAttributes?.[transactionAttributes.trader.key];
            salesPerson = rowTransactionAttributes?.[transactionAttributes.salesPerson.key];
            brokerName = rowTransactionAttributes?.[transactionAttributes.brokerName.key];
        } else {
            productId = data.balances.productId
            transactionTimestamp = data.transaction.timestamp;
            transactionId = data.transaction.txId;
        }

        const counterpartyDisplay = appConfigState.getClientForAddress(counterParty) || shortenAddress(counterParty);

        let beZeroRating = rowProductItemAttributes[carbonProjectAttributes.beZeroRating.key];
        if (beZeroRating === undefined && rowProductItemAttributes[carbonProjectAttributes.projectId.key] !== undefined) {
            beZeroRating = getBeZeroRating(rowProductItemAttributes[carbonProjectAttributes.projectId.key])
        }

        return {
            transactionTimestamp: transactionTimestamp,
            tradeDate: tradeDate,
            tradeId: tradeId,
            transactionId: transactionId,
            forwardId: forwardId,
            transactionType: transactionType,
            counterParty: counterpartyDisplay,
            counterPartyToolTipText: counterParty,
            productId: productId,
            registry: productsData.get(productId)?.attributes[productAttributes.registry.key] || '',
            projectType: rowProductItemAttributes[carbonProjectAttributes.projectType.key],
            projectId: rowProductItemAttributes[carbonProjectAttributes.projectId.key],
            projectName: rowProductItemAttributes[carbonProjectAttributes.projectName.key],
            beZeroRating: beZeroRating,
            vintage: rowProductItemAttributes[carbonProjectAttributes.vintage.key],
            country: rowProductItemAttributes[carbonProjectAttributes.country.key],
            projectState: rowProductItemAttributes[carbonProjectAttributes.projectState.key],
            accreditationCode: rowProductItemAttributes[lgcAttributes.accreditationCode.key],
            fuelSource: rowProductItemAttributes[lgcAttributes.fuelSource.key],
            generationYear: rowProductItemAttributes[lgcAttributes.generationYear.key],
            creationYear: rowProductItemAttributes[lgcAttributes.creationYear.key],
            generationState: rowProductItemAttributes[lgcAttributes.generationState.key],
            greenPowerAccredited: rowProductItemAttributes[lgcAttributes.greenPowerAccredited.key],
            facility: rowProductItemAttributes[miqcAttributes.facility.key],
            segment: rowProductItemAttributes[miqcAttributes.segment.key],
            issueMonth: rowProductItemAttributes[miqcAttributes.issueMonth.key],
            issueYear: rowProductItemAttributes[miqcAttributes.issueYear.key],
            countryOfOperation: rowProductItemAttributes[miqcAttributes.countryOfOperation.key],
            certificateRegion: rowProductItemAttributes[miqcAttributes.certificateRegion.key],
            operatorName: rowProductItemAttributes[miqcAttributes.operatorName.key],
            miqMethaneIntensity: rowProductItemAttributes[miqcAttributes.miqMethaneIntensity.key],
            miqGrade: rowProductItemAttributes[miqcAttributes.miqGrade.key],
            miqGradeStatus: rowProductItemAttributes[miqcAttributes.miqGradeStatus.key],
            miqAuditorName: rowProductItemAttributes[miqcAttributes.miqAuditorName.key],
            eo100Grade: rowProductItemAttributes[miqcAttributes.eo100Grade.key],
            quantity: quantity,
            currency: currency,
            valueDate: valueDate,
            price: price,
            trader: trader,
            salesPerson: salesPerson,
            salesCredits: salesCredits,
            brokerName: brokerName,
            brokerage: brokerage
        };
    };

    const handleRowClick = (row: any) => {
        setSelectedRow(row); // Update the selected row state

        if (row.transactionType === TransactionType.ForwardSell) {
            checkForwardStatus(row);
        }
    };

    const checkForwardStatus = (row: any) => {
        setForwardTradeState(ForwardTradeState.CheckingProductItem);
        fetchTransactionsByProductItem(row.forwardId, cortenApi).then(forwardTransactions => {
            let transactionTypes = forwardTransactions.list.map((tx: any) => tx.request.type).filter((txType: string) => txType !== 'CreateProductItemRequest');
            if (transactionTypes.length === 0) {
                setForwardTradeState(ForwardTradeState.AvailableForDeliveryOrCancelation);
            } else if (transactionTypes[0] === 'AssignHoldingRequest') {
                setForwardTradeState(ForwardTradeState.AlreadyDelivered);
            } else if (transactionTypes[0] === 'DeleteProductItemRequest') {
                setForwardTradeState(ForwardTradeState.AlreadyCanceled);
            }
        });
    }

    const handleCloseDialog = () => {
        setSelectedRow(undefined); // Close the dialog by resetting the selected row
        if (dialogActionsTaken) {
            // If any actions were taken on this trade in the dialog then refresh the table of data
            refreshTable({
                filterItems: filterModel.items,
                sortItems: sortModel,
            });
        }
        setDialogActionsTaken(false);
        setForwardTradeState(ForwardTradeState.Initialisation);
    };

    const requestDeliverToForwardTrade = () => {
        setForwardTradeState(ForwardTradeState.ConfirmingToDeliver);
    }

    const requestCancelForwardTrade = () => {
        setForwardTradeState(ForwardTradeState.ConfirmingToCancel);
    }

    const actionsTakenWhenActingOnForwardTrade = () => {
        setDialogActionsTaken(true);
    }

    const generateExportFileName = () => {
        return `${pageType}${currentDateExportFormat()}`;
    };

    const filterAndOrderColumnsByDefinition = (columns: GridColDef<TradeRow>[]): GridColDef<TradeRow>[] => {
        let selectedColumns: GridColDef<TradeRow>[] = [];
        for (let columnDefinition of columnDefinitions) {
            let matchingColumn = columns.find(column => column.field === columnDefinition.key);
            if (matchingColumn !== undefined) {
                selectedColumns.push(matchingColumn);
            } else {
                throw new Error(`Could not find column named ${columnDefinition.key} in the set of field definitions.`);
            }
        }
        return selectedColumns;
    }

    /**
     * Apply the 'filterable' property on all columns in the definition. Any columns that match
     * the page filters that currently apply will be set to un-filterable. In addition, there
     * are several other conditions that will cause some columns to be not able to be filtered.
     * @param columns 
     * @param pageType 
     * @param pageFilters 
     * @returns 
     */
    const applyFilterableProperty = ({
        columns,
        pageType,
        pageFilters
    }: {
        columns: GridColDef<TradeRow>[],
        pageType: TradePageType,
        pageFilters: TradePageFilters
    }): GridColDef<TradeRow>[] => {
        for (let column of columns) {
            // Disable filtering for any column that is currently being used in the page filters
            if (pageFilters.hasOwnProperty(column.field) && pageFilters[column.field as keyof TradePageFilters] !== undefined) {
                column.filterable = false;
            }
            // Disable filtering on counterparty and quantity in the Forwards views because ListProductItemRequest does not support these
            if (pageType === TradePageType.Forwards && ['counterParty', 'quantity'].includes(column.field)) {
                column.filterable = false;
            }
            // Disable filtering on the Product column if we have chosen a product-based entry from the column set dropdown
            if (column.field === 'productId' && productBasedColumnSets.includes(chosenColumnSet)) {
                column.filterable = false;
            }
            // Disable filtering on the project name column if we have a page filter on project ID
            if (column.field === 'projectName' && pageFilters.projectId) {
                column.filterable = false;
            }
            // Disable filtering on the value date column if we have either start or end range defined as page filters
            if (column.field === 'valueDate' && (pageFilters.valueDateStart || pageFilters.valueDateEnd)) {
                column.filterable = false;
            }
        }
        return columns;
    }

    /* Get a unique ID to describe each row. This is important for DataGrid to work correctly */
    const getUniqueRowId = (row: TradeRow) => {
        const rowProduct = appConfigState.getProduct(row.productId)!.displayCode as ProductType
        let productSpecificUniqueString = undefined;
        if ([ProductType.ACCU, ProductType.VCU].includes(rowProduct)) {
            productSpecificUniqueString = `${row.projectId}-${row.vintage}`;
        } else if (rowProduct === ProductType.LGC) {
            productSpecificUniqueString = `${row.accreditationCode}-${row.creationYear}`;
        } else if (rowProduct === ProductType.MiQC) {
            productSpecificUniqueString = `${row.facility}-${row.issueMonth}`;
        }
        if (productSpecificUniqueString === undefined) {
            throw new Error(`No unique row ID defined for product ${rowProduct}, row ${row}`);
        }
        return `${row.transactionId}-${productSpecificUniqueString}`;
    }

    const transactionTypeToDisplayTradeField: any = [
        TransactionType.PhysicalSell,
        TransactionType.PhysicalSell,
        TransactionType.ForwardBuy,
        TransactionType.ForwardSell,
    ];
    const isShowTrader = selectedRow?.trader
        ? true
        : Boolean(transactionTypeToDisplayTradeField.includes(selectedRow?.transactionType || ''));

    return (
        <>
            {
                pageLoading ?
                    <LinearProgress />
                    :
                    <div style={{width: '100%', height: 'auto', overflow: "auto", marginBottom: '5rem'}}>
                        <DataGrid
                            autoHeight={true}
                            slots={{ toolbar: (pageType === TradePageType.TradeBlotter) ? ExtendedToolbarWithColumnSelector : GridToolbar }}
                            slotProps={{ toolbar: {
                                 csvOptions: { fileName: generateExportFileName() },
                                 printOptions: { disableToolbarButton: true },
                             } }}
                            rows={rows}
                            getRowId={row => getUniqueRowId(row)}
                            disableRowSelectionOnClick
                            getRowHeight={() => 'auto'}
                            columnHeaderHeight={100}
                            sx={{
                                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                            }}
                            loading={tableLoading}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) => {
                                storeColumnVisibilityModel(chosenColumnSet, newModel, getTableNameForStorageKey);
                                setColumnVisibilityModel(newModel);
                            }}

                            // Pagination props
                            paginationMode="server"
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={[PAGE_SIZE]}
                            rowCount={rowCount}

                            // Filter props
                            filterMode="server"
                            filterModel={filterModel}
                            onFilterModelChange={(newFilterModel) => {
                                handleFilterModelChange(newFilterModel);
                            }}

                            // Sort props
                            sortingMode='server'
                            sortModel={sortModel}
                            onSortModelChange={(newSortModel) => {
                                handleSortModelChange(newSortModel)
                            }}

                            columns={
                                applyFilterableProperty({
                                    columns: filterAndOrderColumnsByDefinition(
                                        getDataGridColumnDefinitions(appConfigState, productsData, filterOperators)
                                    ),
                                    pageType: pageType,
                                    pageFilters: pageFilters
                                })
                            }
                            density="compact"
                            disableDensitySelector
                            onRowClick={params => handleRowClick(params.row)}
                        />

                    </div>
            }
            {selectedRow && forwardTradeState === ForwardTradeState.ConfirmingToDeliver && (
                <ForwardTradeDeliverDialog
                    dialogActive={true}
                    forwardTradeRow={selectedRow!}
                    onActionTaken={actionsTakenWhenActingOnForwardTrade}
                    onClose={handleCloseDialog}
                />
            )}
            {selectedRow && forwardTradeState === ForwardTradeState.ConfirmingToCancel && (
                <ForwardTradeCancelDialog
                    dialogActive={true}
                    forwardTradeRow={selectedRow!}
                    onActionTaken={actionsTakenWhenActingOnForwardTrade}
                    onClose={handleCloseDialog}
                />
            )}
            {selectedRow && forwardTradeState !== ForwardTradeState.ConfirmingToDeliver && forwardTradeState !== ForwardTradeState.ConfirmingToCancel && (
                <TransactionOverview
                    open={!!selectedRow}
                    onClose={handleCloseDialog}
                    title="Transaction Details"
                    actions={
                        selectedRow!.transactionType !== TransactionType.ForwardSell ? []
                        : forwardTradeState === ForwardTradeState.Initialisation ? []
                        : forwardTradeState === ForwardTradeState.CheckingProductItem ? [{
                            actionType: ActionType.LoadingBar
                        }]
                        : forwardTradeState === ForwardTradeState.AlreadyDelivered ? [{
                            actionType: ActionType.Info,
                            label: "This forward has been delivered"
                        }]
                        : forwardTradeState === ForwardTradeState.AlreadyCanceled ? [{
                            actionType: ActionType.Info,
                            label: "This forward has been canceled"
                        }]
                        : forwardTradeState === ForwardTradeState.AvailableForDeliveryOrCancelation ? [{
                            actionType: ActionType.Button,
                            label: "Deliver Units",
                            action: requestDeliverToForwardTrade
                        },
                        {
                            actionType: ActionType.Button,
                            label: "Cancel Forward",
                            action: requestCancelForwardTrade
                        }]
                        : []
                    }
                    productType={appConfigState.getProduct(selectedRow!.productId)?.displayCode as ProductType}
                    uiElements={{
                        ...(pageType !== TradePageType.Forwards) ? {'transactionTimestamp': { value: selectedRow!.transactionTimestamp }} : {},
                        'transactionType': { value: selectedRow!.transactionType },
                        ...(isTrade(selectedRow.transactionType) && { 'tradeDate': { value: selectedRow!.tradeDate } }),
                        ...pageType === TradePageType.Forwards ? {
                            'forwardId': { value: selectedRow!.forwardId }
                        } : {
                            'transactionId': { value: selectedRow!.transactionId }
                        },
                        ...(isTrade(selectedRow.transactionType) && {
                            'tradeId': { value: selectedRow!.tradeId }
                        }),
                        ...(isShowTrader && { 'trader': { value: selectedRow!.trader?.toString() } }),
                        ...(isTrade(selectedRow.transactionType) && { 'counterParty': { value: selectedRow!.counterParty } }),
                        'quantity': { value: selectedRow!.quantity.toString() },
                        'registry': { value: selectedRow!.registry },
                        'productId': { value: selectedRow!.productId },

                        ...getProductAttributeUiElements({
                            data: selectedRow,
                            fieldDefinitions: fieldDefinitions,
                            productType: appConfigState.getProduct(selectedRow!.productId)?.displayCode as ProductType
                        }),

                        ...pageType === TradePageType.Forwards && {
                            'valueDate': { value: selectedRow!.valueDate }
                        },
                        ...(isTrade(selectedRow.transactionType)) && {
                            'currency': { value: selectedRow!.currency },
                            'price': { value: selectedRow!.price?.toString() },
                            'salesPerson': { value: selectedRow!.salesPerson?.toString() },
                            'salesCredits': { value: selectedRow!.salesCredits?.toString() },
                            'brokerName': { value: selectedRow!.brokerName?.toString() },
                            'brokerage': { value: selectedRow!.brokerage?.toString() }
                        }
                    }}
                    relatedProperties={new Map([
                        ['counterPartyToolTipText', selectedRow!.counterPartyToolTipText]
                    ])}
                    fieldDefinitionMap={fieldDefinitionMap}
                />
            )}
        </>
    );
};

export { Trades, type TradeRow, TradePageType, ForwardTradeState };
