import { Box, Button, LinearProgress, Skeleton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useInventoryManagementState } from './state/InventoryManagementState';
import { useProductState } from './state/ProductState';
import { HoldingLockToMarket } from '../../component/HoldingLockToMarket';
import { useLockedHoldings } from './state/LockedHoldingState';
import LockedHoldings from './LockedHoldings';
import { TradePageType, Trades } from '../../component/Trades';
import { useAuth, useCortenApiState } from '@trovio-tech/trovio-core-api-jsx';
import {
    AmountFormatWrapper,
    currentDateExportFormat,
    DataTableToolbar,
    fetchProducts,
    fetchProjectPrices,
    fieldDefinitionToDataTableColumnDefinition,
    getCustomProductAmountFieldDefinition,
    getDataTableColumnDefinitions,
    FieldDefinition,
    FieldDefinitionKey,
    HoldingRetirement,
    inventoryAccount,
    ProductDataRaw,
    ProductType,
    ProjectData,
    StyledTabs,
    transactionAttributes,
    useAppConfigState,
    useAppNavigate,
    useLayoutState,
    useProductDataState,
    useCommodityDeskApiContext,
} from '@commodity-desk/common';
import { homepage } from '../../state/Variables';
import { useNavigate } from 'react-router-dom';
import { ProjectPricesResponse } from '@commodity-desk/commodity-desk-api-js';
import {
    DataTable,
    DataTableColumnProps,
    filterOperator,
    useFilter
} from '@trovio-ui-libs/ui';
import { FilterOperatorProps } from '@trovio-ui-libs/ui';
import { dataTableFilterToCoreFilter } from '../../utility/DataTableUtil';
import { UnlockHoldings } from './UnlockHoldings';

type TabType = 'Projects' | 'LockedHoldings' | 'ForwardTrades';

const Projects = () => {
    const { mblSelectedProduct, fetchAndInitProducts } = useProductState();
    const { cortenApi, cortenAuthApi } = useCortenApiState();
    const { uniqueProjectsDisplay, findUniqueProjects, loadingProjects } = useInventoryManagementState();
    const [retirementDialogActive, setRetirementDialogActive] = useState(false);
    const [lockToMarketDialogActive, setLockToMarketDialogActive] = useState(false);
    const [unlockHoldingsDialogActive, setUnlockHoldingsDialogActive] = useState(false);
    const [product, setProduct] = useState<ProductDataRaw | undefined>(undefined);

    const navigate = useNavigate();
    const appNavigate = useAppNavigate({ homepage, navigate });
    const appConfigState = useAppConfigState();
    const { productsData } = useProductDataState();
    const [tabValue, setTabValue] = useState<TabType>('Projects');
    const { customTheme } = useLayoutState();
    const { resetTable } = useLockedHoldings();

    const [prices, setPrices] = useState<ProjectPricesResponse>();
    const user = useAuth();
    const { commodityDeskApi } = useCommodityDeskApiContext();

    const { filters, onFiltersChange } = useFilter();

    useEffect(() => {
        fetchProducts(
            cortenApi,
            inventoryAccount.id,
            appConfigState.getProducts(true)
        ).then((foundProducts) => {
            if (foundProducts) {
                const filteredProduct = foundProducts.find(
                    (item: ProductDataRaw) => mblSelectedProduct.id === item.productId
                );
                setProduct(filteredProduct);
            } else {
                setProduct(undefined);
            }
        });
        fetchProjectPrices({
            productId: mblSelectedProduct.id,
            api: commodityDeskApi
        }).then(setPrices);
    }, [mblSelectedProduct]); // eslint-disable-line react-hooks/exhaustive-deps

    const openRetirementDialog = () => {
        setRetirementDialogActive(true);
    };

    const onRetirementDialogClosed = () => {
        setRetirementDialogActive(false);
        fetchAndInitProducts(mblSelectedProduct.id);
    };

    const openLockToMarketDialog = () => {
        setLockToMarketDialogActive(true);
    };

    const onLockToMarketDialogClosed = () => {
        setLockToMarketDialogActive(false);
        fetchAndInitProducts(mblSelectedProduct.id);
        resetTable();
    };

    const openUnlockHoldingsDialog = () => {
        setUnlockHoldingsDialogActive(true);
    };

    const onUnlockHoldingsDialogClosed = () => {
        setUnlockHoldingsDialogActive(false);
        fetchAndInitProducts(mblSelectedProduct.id);
        resetTable();
    };

    const generateExportFileName = () => {
        const productDisplayCode = appConfigState.getProduct(mblSelectedProduct.id)?.displayCode;
        return `${productDisplayCode}Projects${currentDateExportFormat()}`;
    };


    const onTabChange = (event: React.SyntheticEvent, value: any): void => {
        setTabValue(value);
    };

    const getProjectPrice = (projectId: string): string | number => {
        const projectPrices = prices?.projectPrices.find(
            (project) => project.projectId === projectId
        );
        // need to return existing price as a number so the min/ max decimal assignment can be applied in the renderCell
        return projectPrices?.price?.toString() ? projectPrices.price : '-';
    };

    // Handle side effects of filters  
    useEffect(() => {
        if (filters.length > 0) {
            const attributeFilters= dataTableFilterToCoreFilter(filters);
            findUniqueProjects(attributeFilters);
        } else {
            findUniqueProjects([]);
        }
    }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

    // Define the standard filter operators
    const standardFilterOperators: FilterOperatorProps[] = [
        filterOperator.contains(),
        filterOperator.equals(),
        filterOperator.startsWith(),
        filterOperator.endsWith(),
        filterOperator.isAnyOf()
    ];

    /**
     * Filters an input list of column definitions down to only include columns that we want to display in this table, based on the keys
     * that exist in requiredOrderedColumns. Also re-orders the column definitions to be in the order as specified in requiredOrderedColumns.
     * 
     * @param columns A list of all {@link DataTableColumnProps} that were found from the field definitions (filtered by applicable product)
     * @returns A list of {@link DataTableColumnProps} that only includes the columns we want to show here in this table
     */
    const filterAndOrderColumns = (columns: DataTableColumnProps<ProjectData>[], requiredOrderedColumns: FieldDefinitionKey[]): DataTableColumnProps<ProjectData>[] => {
        let selectedColumns: DataTableColumnProps<ProjectData>[] = [];
        for (let columnKey of requiredOrderedColumns) {
            let matchingColumn = columns.find(column => column.key === columnKey);
            if (matchingColumn !== undefined) {
                selectedColumns.push(matchingColumn);
            }
        }
        return selectedColumns;
    }

    const displayedColumnKeys: FieldDefinitionKey[] = [
        'projectId',
        'projectName',
        'projectState',
        'country',
        'projectType',
        'beZeroRating',
        'inventoryAmount',
        'escrowAmount',
        'clientAmountAssigned',
        'price'
    ];

    const extraFieldDefinitions: FieldDefinition[] = [
        getCustomProductAmountFieldDefinition({
            key: "inventoryAmount",
            displayName: `${inventoryAccount.display} Holdings`,
            columnMinWidth: 200,
            productsData: productsData,
            selectedProductId: mblSelectedProduct.id
        }),
        getCustomProductAmountFieldDefinition({
            key: "escrowAmount",
            displayName: "Market Holdings (Locked)",
            columnMinWidth: 260,
            productsData: productsData,
            selectedProductId: mblSelectedProduct.id
        }),
        getCustomProductAmountFieldDefinition({
            key: "clientAmountAssigned",
            displayName: "Client Holdings",
            columnMinWidth: 250,
            productsData: productsData,
            selectedProductId: mblSelectedProduct.id
        }),
    ]

    const columnDefinitions = getDataTableColumnDefinitions<ProjectData>({
        appConfigState: appConfigState,
        productsData: productsData,
        filterOperators: {
            standardFilterOperators: standardFilterOperators
        },
        chosenProduct: mblSelectedProduct.code as ProductType
    }).concat(extraFieldDefinitions.map((extraFieldDefinition) => fieldDefinitionToDataTableColumnDefinition(extraFieldDefinition, standardFilterOperators)));

    // Override header and set a custom rendering function for the price column
    const priceColumn = columnDefinitions.find(c => c.key === 'price')!
    priceColumn.header = `Price ${appConfigState.getProduct(mblSelectedProduct.id)?.currency ?? ''}`;
    priceColumn.cell = ({ table, row }) => {
        let priceValue = prices ? getProjectPrice(row!.projectId as string) : undefined;
        if (prices && !priceValue) {
            // If prices have loaded and price value is undefined then render it as an empty cell in the table
            return priceValue;
        } else {
            return !prices ? (
                <Skeleton animation="wave" width={40} height={10} />
            ) : (
                <AmountFormatWrapper amount={Number(priceValue)} minDecimalPos={2} maxDecimalPos={2} />
            );
        }
    }

    const columns = filterAndOrderColumns(columnDefinitions, displayedColumnKeys);

    return (
        <Box style={{ marginBottom: '5rem' }}>
            <Typography variant="h3">Projects, Locked Holdings and Forward Trades</Typography>
            <Stack direction="row" spacing={2} marginBottom={1} alignItems="baseline">
                <Button
                    disabled={product === undefined}
                    variant="outlined"
                    onClick={openRetirementDialog}
                >
                    Retire
                </Button>
                {retirementDialogActive && (
                    <HoldingRetirement
                        useDialog={true}
                        accountId={inventoryAccount.id}
                        holdings={[]}
                        preSelectedProductId={product?.productId}
                        retirementDialogActive={retirementDialogActive}
                        onRetirementDialogClosed={onRetirementDialogClosed}
                        productsData={productsData}
                        customTheme={customTheme}
                        appConfigState={appConfigState}
                        user={user}
                        commodityDeskApi={commodityDeskApi}
                        cortenApi={cortenApi}
                        cortenAuthApi={cortenAuthApi}
                    />
                )}
                <Button
                    disabled={product === undefined}
                    variant="outlined"
                    onClick={openLockToMarketDialog}
                >
                    Lock to Market
                </Button>
                {lockToMarketDialogActive && (
                    <HoldingLockToMarket
                        lockToMarketDialogActive={lockToMarketDialogActive}
                        preSelectedProductId={product?.productId}
                        onLockToMarketDialogClosed={onLockToMarketDialogClosed}
                    />
                )}
                <Button
                    disabled={product === undefined}
                    variant="outlined"
                    onClick={openUnlockHoldingsDialog}
                >
                    Unlock from Market
                </Button>
                {unlockHoldingsDialogActive && (
                    <UnlockHoldings
                        unlockHoldingsDialogActive={unlockHoldingsDialogActive}
                        preSelectedProductId={product?.productId}
                        onUnlockHoldingsDialogClosed={onUnlockHoldingsDialogClosed}
                        holdings={[]}
                    />
                )}
            </Stack>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 1 }}>
                <StyledTabs
                    value={tabValue}
                    onChange={onTabChange}
                    tabs={[
                        { label: 'Projects', value: 'Projects' },
                        { label: 'Locked Holdings', value: 'LockedHoldings' },
                        { label: 'Forward Trades', value: 'ForwardTrades' }
                    ]}
                    customTheme={customTheme}
                />
            </Box>
            {loadingProjects && <LinearProgress />}

            {tabValue === 'Projects' && (
                <Box style={{ width: '100%', height: 'auto', overflow: 'auto' }}>
                    <DataTable
                        loading={loadingProjects}
                        columns={columns}
                        data={uniqueProjectsDisplay}
                        filters={filters}
                        onFiltersChange={onFiltersChange}
                        paginationPageSizes={[25, 50, 100]}
                        onRowDoubleClick={(row) =>
                            appNavigate(
                                `/inventory-management/product/${mblSelectedProduct.id}/project/${row.projectId}`
                            )
                        }
                        toolbar={(table) => {
                            return (
                                <DataTableToolbar
                                    table={table}
                                    exportFileName={generateExportFileName()}
                                />
                            );
                        }}
                    />
                </Box>
            )}
            {tabValue === 'LockedHoldings' && <LockedHoldings />}
            {tabValue === 'ForwardTrades' && (
                <Trades
                    pageType={TradePageType.Forwards}
                    columnDefinitions={[
                        { key: 'valueDate', showByDefault: true },
                        { key: 'tradeDate', showByDefault: false },
                        { key: 'forwardId', showByDefault: true },
                        { key: 'tradeId', showByDefault: true },
                        { key: 'quantity', showByDefault: true },
                        { key: 'productId', showByDefault: false },
                        { key: 'projectType', showByDefault: true },
                        { key: 'projectId', showByDefault: false },
                        { key: 'projectName', showByDefault: false },
                        { key: 'vintage', showByDefault: true },
                        { key: 'currency', showByDefault: true },
                        { key: 'price', showByDefault: true },
                        { key: 'counterParty', showByDefault: true },
                        { key: 'trader', showByDefault: false },
                        { key: 'salesPerson', showByDefault: false },
                        { key: 'salesCredits', showByDefault: false },
                        { key: 'brokerName', showByDefault: false },
                        { key: 'brokerage', showByDefault: false }
                    ]}
                    defaultOrdering={`productItemAttribute.${transactionAttributes.valueDate.key}`}
                    pageFilters={{product: mblSelectedProduct.id}}
                />
            )}
        </Box>
    );
};

export default Projects;
